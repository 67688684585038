const savings = {
  smallBox: {
    time: 2,
    meals: 10,
  },
  largeBox: {
    time: 4,
    meals: 10,
  },
  extraLargeBox: {
    time: 16,
    meals: 100,
  },
}

export const contents = {
  '114': {
    displayName: 'Ranchers Classic',
    sku: 'B9152',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 45,
    contents: [
      '2 (17oz) T-Bones or Bone-in NY Strips',
      '5 (4oz) Flat Iron Steaks or 5 (6oz) Top Sirloin Steaks',
      '2 (1lb) Signature Angus Ground Beef Packs',
      '16 (4.5oz) Plain Chicken Breasts',
    ],
    description:
      "The Ranchers Classic is the box that started it all! It's our best seller for a reason. You will enjoy the classic combination of American beef & chicken that set us apart from the competition and the grocery store. Every piece is hand cut and trimmed to perfection to create excellence that you'll taste in each bite. This box is the perfect way for you to try and fall in love with American Meat Delivered.",
    tags: ['beef', 'chicken'],
    faq: [
      {
        title: 'How many meals does this box provide?',
        body:
          'Based on an average 5oz serving size of meat per person, this box would provide 32 servings of delicious American meat!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },

      {
        title: 'How is each cut of meat in the box packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control very easy.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What does the “or” option mean when there are two listed cuts?',
        body:
          'This means that you could receive the first option OR the second option in your box. No one cut is guaranteed, but you are guaranteed one of the options listed.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: true,
    stickerTitle: 'Best \n Seller',
    stickerColor: '#FFBF3F',
    stickerTextColor: 'night-800',
    hasDeal: true,
    dealInfo: 'Save 10% On Your Whole Order—Use Code CLASSIC10.',
    dealCoupon: 'CLASSIC10',
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
  },
  '113': {
    displayName: 'The Cattleman',
    sku: 'B9196',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 41,
    contents: [
      '2 (17oz) T-Bones or Bone-in NY Strips',
      '5 (4oz) Flat Iron Steaks or 4 (6oz) Strip Cut Sirloin Steaks',
      '2 (1lb) Signature Angus Ground Beef Packs',
      '5 (6oz) Top Sirloin Steaks',
      '4 (6 oz) Steak Cut Sirloin Steaks',
    ],
    description:
      "The Cattleman is full of beef that is 100% born, raised, and harvested in the USA. You'll enjoy locally sourced, pasture raised, no added hormone or antibiotic beef done the right way. Every piece is USDA Prime or Upper Choice—steakhouse quality that is cut by hand. Our product is the best because our process is the best. Treat yourself to beef that is made and aged to perfection today.",

    tags: ['beef'],
    faq: [
      {
        title: 'What does the “or” option mean when there are two listed cuts?',
        body:
          'This means that you could receive the first option OR the second option in your box. No one cut is guaranteed, but you are guaranteed one of the options listed.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: "Are the burgers different from what's in the Burger box?",
        body:
          'Yes, the burgers included in the Cattleman box are our signature ground beef burgers, whereas the burgers in the Burger box are our American wagyu ground beef blend. ',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What is the lean-to-fat content in the signature beef blend?',
        body:
          'Our blend is an 80/20 ratio that gives tons of rich flavor in whatever dish you use it in.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Are your steaks aged?',
        body:
          'Yes, we age our steaks a minimum of 21 days to help them reach the peak of tenderness. That’s why our steaks are consistently more tender and delicious than other choices in the market.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
  },
  '112': {
    displayName: 'Family Feast Bundle',
    sku: 'B9999',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 91,
    contents: [
      '2 (17oz) T-Bones or Bone-in NY Strips',
      '5 (4oz) Flat Iron Steaks or 5 (6oz) Top Sirloin Steaks',
      '2 (1lb) Signature Angus Ground Beef Packs',
      '5 (6oz) Top Sirloin Steaks',
      '4 (6 oz) Steak Cut Sirloin Steaks',
      '8 (4.5oz) Italian Seasoned Chicken Breasts',
      '8 (4.5oz) Garlic Pepper Chicken Breasts',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
      '8 (4.5oz) Plain Chicken Breasts',
      '8 (4.5oz) Assorted Chicken Breasts',
    ],
    description:
      "The Family Feast truly is a box for a growing and hungry family. This 100% American, steakhouse quality beef, and better than organic chicken delivered to your doorstep makes meals easy and helps family dinners be a regular thing again. You can know that you're feeding your family the best America has to offer when you have a Family Feast in your fridge. Stock up on high-quality meats for your family today.",

    tags: ['beef', 'chicken'],
    faq: [
      {
        title: 'What is the lean-to-fat content in the signature beef blend?',
        body:
          'Our blend is an 80/20 ratio that gives tons of rich flavor in whatever dish you use it in.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Can I substitute one item or flavor for another?',
        body:
          'We currently do not allow substitutions of flavors or items in our boxes. A custom box option is in the works to give you greater control to buy the products you love the most. Stay tuned!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What is grass-fed grain finished?',
        body:
          'Grass-fed cows that are grain-finished eat grass most of their lives and then are introduced a blend of grass and grains in the last few months to help them grow and put on healthy weight to produce quality, marbled beef that obtains the highest Prime standard the USDA gives to beef.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'How many meals does this include?',
        body:
          'Based on an average 5oz serving size of meat per person, this box would provide a little over 66 servings of delicious American meat!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    mealsDonated: savings.largeBox.meals,
    timeSaved: savings.largeBox.time,
    maxAddOns: 2,
    contentsVolume: 10,
  },
  '115': {
    displayName: 'Better Than Organic Chicken',
    sku: ['B9516', 'PLAINBTOC'],
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 50,
    contents: [
      '8 (4.5oz) Italian Seasoned Chicken Breasts',
      '8 (4.5oz) Garlic Pepper Chicken Breasts',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
      '8 (4.5oz) Plain Chicken Breasts',
      '8 (4.5oz) Assorted Chicken Breasts',
    ],
    description:
      "Chicken at the store can leave you thinking chicken isn't for you, but that's because you haven't had our chicken! Pasture raised, no antibiotics EVER, no hormones, and raised in a way that is better than the organic standard. The only thing that can rival our steaks is our chicken, so get this box to make sure you and your family finally get to experience chicken done the right way.",

    tags: ['chicken'],
    faq: [
      {
        title: 'How is this chicken better than Organic?',
        body:
          'We don’t aim to earn a label, but rather to earn your trust. Our chicken comes from a NAE (No Antibiotics Ever) program, which means they are free of antibiotics and added hormones their entire lives. That coupled with how tender and easy to prepare our chicken is, are just some of the reasons why we are truly better than organic.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'Can you specify all plain or pre-seasoned?',
        body:
          'Yes! You now have the option to select either plain chicken or marinated chicken breasts for your order. Just head up to the “Preparation” section above and choose your preferred mix. Enjoy our pre-marinated options or craft your own flavor with our plain chicken breasts. Either choice is a delicious one!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Are the seasonings gluten-free?',
        body: 'Yes, all our seasonings are completely Gluten Free! ',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'How is each cut of meat in the box packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control very easy.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
    hasFlavorOptions: true,
    plainContents: [
      '32 (4.5 oz) Plain Chicken Breasts',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
    ],
  },
  // '116': {
  //   displayName: 'Prime Seafood',
  //   sku: '4774621',
  //   options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
  //   meals: 32,
  //   contents: [
  //     '2 (8oz) Rainbow Trout Skin On Boneless or 2lbs Wild Caught Bay Scallops',
  //     '4 (8oz) Skin On Boneless Atlantic Salmon Filets',
  //     '4 (8oz) Wild Caught Pacific Snapper Boneless',
  //     '4lbs of Wild Caught Shrimp Peeled and Deveined',
  //   ],
  //   description:
  //     "Our Prime Seafood is caught, cut, and packaged at sea. This allows you to enjoy it at the peak of freshness. Whether you need it for a night of meal prep on the weekend or a dinner during the week, our salmon, snapper, shrimp, or trout will always be at its best. Green rated for sustainability, our process ensures harm of bycatch is limited and we leave the habitats and environments better than we found them. Eat great, feel great, and always have the seafood you're craving on hand.",

  //   tags: ['seafood'],
  //   faq: [
  //     {
  //       title: 'How is “frozen” fish really “fresh” fish?',
  //       body:
  //         'Most seafood sold these days is frozen at some point. In the seafood world, freezing fish after the catch is normal, and it seals in quality at the peak freshness. If it’s frozen correctly, like how we do it, it preserves the quality better than the majority of the “fresh” seafood on the market today.',
  //       btnText: 'Visit Our About Page',
  //       btnLink: 'about#sourcing',
  //     },
  //     {
  //       title: 'Is the seafood sustainably caught?',
  //       body:
  //         'Yes, our Seafood is Green Rated for Sustainability. We do everything we can to preserve the environments we fish in and reduce the harm of bycatch.',
  //       btnText: 'Visit Our About Page',
  //       btnLink: 'about#sourcing',
  //     },
  //     {
  //       title: 'How is each cut of seafood in the box packaged?',
  //       body:
  //         'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control quick and easy.',
  //       btnText: 'Visit Our About Page',
  //       btnLink: 'about#sourcing',
  //     },
  //     {
  //       title: 'Do I have to thaw the seafood before cooking?',
  //       body:
  //         'You can skip thawing the fish and cook it frozen straight from the freezer. Remember though, doing so will add more minutes to the total cook time. Keep an eye on the color and internal temperature to ensure you get the cook you want when using frozen fish.',
  //       btnText: 'Visit Our Help Center',
  //       btnLink: 'help-center',
  //     },
  //   ],
  //   sticker: null,
  //   stickerTitle: null,
  //   stickerColor: null,
  //   stickerTextColor: null,
  //   mealsDonated: savings.smallBox.meals,
  //   timeSaved: savings.smallBox.time,
  // },
  '121': {
    displayName: 'The Cowboy',
    sku: '4844891',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 38,
    contents: [
      '4 (18oz) Bone-in Ribeyes or (16oz) Boneless Ribeyes',
      '3 (10oz) Black Angus N.Y. Strips',
      '5 (6oz) Black Angus Top Sirloin Steaks',
      '8 (6oz) American Wagyu Steak Burgers',
    ],
    description:
      "The Cowboy is perfect for steak lovers! It's full of Black Angus beef that is 100% born, raised, and harvested in the USA. Every piece is USDA Prime or Upper Choice—steakhouse quality that is cut by hand. From the Bone-in Ribeyes to the Wagyu Burgers, this is an experience you do not want to miss. Take your meals to the next level with the Cowboy Box.",

    tags: ['beef'],
    faq: [
      {
        title: 'What is Black Angus?',
        body:
          'Black Angus is a specific breed of cattle, very often used in steakhouses for its coveted flavor and amazing marbling. When you’re getting USDA prime and Upper Choice Black Angus, you’re getting some of the best beef on the market.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Are your cattle grass-fed?',
        body:
          'Yes, all of our cows are grass-fed. They are not grass-finished but are instead grain-finished to make sure we consistently produce the best beef in America. You can read all about our sourcing on our about page.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'Are your steaks aged?',
        body:
          'Yes, we age our steaks a minimum of 21 days to help them reach the peak of tenderness. That’s why our steaks are consistently more tender and delicious than other choices in the market.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What does USDA Prime & Upper Choice mean?',
        body:
          'These are two grades of beef from the USDA. The grade refers to the quality of marbling, color, and age of the beef. Prime and Upper Choice are the highest and most sought-after grades.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
    ],
    sticker: true,
    stickerTitle: "Founder's Favorite",
    stickerColor: '#285780',
    stickerTextColor: 'white',
    hasDeal: true,
    dealInfo:
      'Enjoy $30 off every Cowboy box you buy with code GRILLMASTER at checkout.',
    dealCoupon: 'GRILLMASTER',
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
  },
  '122': {
    displayName: 'Butchers Bundle',
    sku: 'HB2021',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 83,
    contents: [
      '2 (17oz) Bone-in Steaks (T-Bones or NY Strips)',
      '5 (4oz) Flat Iron Steaks or 4 (6oz) Strip Cut Sirloin Steaks',
      '2 (1lb) Signature Angus Ground Beef Packs',
      '4 (18oz) Bone-in Ribeyes or (16oz) Boneless Ribeyes',
      '3 (10oz) Black Angus N.Y. Strips',
      '5 (6oz) Black Angus Top Sirloin Steaks',
      '8 (6oz) American Wagyu Steak Burgers',
      '16 (4.5oz) Plain Chicken Breasts',
    ],
    description:
      "Our Butchers Bundle lets you explore all the delicious cuts of beef we sell while still giving you a taste of our fan favorite, pasture-raised chicken! To make this time of year easy and delicious, all of our products are trimmed and cut by hand, then aged according to our standards of excellence to give you the most flavorful and tender cuts of beef on the market. We've done the shopping for you and taken the guesswork out of the meat aisle. Fill your fridge and freezer with America's best today.",

    tags: ['beef', 'chicken'],
    faq: [
      {
        title: 'What is Black Angus?',
        body:
          'Black Angus is a specific breed of cattle, very often used in steakhouses for its coveted flavor and amazing marbling. When you’re getting USDA prime and Upper Choice Black Angus, you’re getting some of the best beef on the market.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'How is each cut of meat in the box packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control very easy.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What does USDA Prime & Upper Choice mean?',
        body:
          'These are two grades of beef from the USDA. The grade refers to the quality of marbling, color, and age of the beef. Prime and Upper Choice are the highest and most sought-after grades.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'Are your steaks aged?',
        body:
          'Yes, we age our steaks a minimum of 21 days to help them reach the peak of tenderness. That’s why our steaks are consistently more tender and delicious than other choices in the market.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    mealsDonated: savings.largeBox.meals,
    timeSaved: savings.largeBox.time,
    maxAddOns: 2,
    contentsVolume: 10,
  },
  '125': {
    displayName: 'Prepper Kit',
    sku: 'B9888',
    options: ['ONETIME_PURCHASE', '6_WEEKS', '8_WEEKS', '10_WEEKS'],
    meals: 280,
    contents: [
      '8.5lbs Bone-in steaks (USDA Prime or Upper Choice)',
      '6.25lbs Boneless steaks (USDA Prime or Upper Choice)',
      '8lbs Signature Angus Ground Beef',
      '18lbs Pre-trimmed, Plain Chicken Breasts',
    ],
    description:
      'Our Prepper Kit is the perfect way to stock up and fill your freezer. With grocery stores experiencing unforeseen shortages on your essentials and inflation continuing to rise, the prepper kit is the best place to get safe, affordable, American meat delivered to your door. Plus, all our pasture-raised beef & chicken can be stored for up to a year in the freezer. In our prepper pack you will receive nearly 135 (5oz) servings of 100%, steakhouse quality, delicious American meat. Order yours today and stock up on over 40lbs lbs of meat sourced from real, American farms. When your prepper kit arrives, so does peace of mind. Subscribe to always have your freezer stocked!',

    tags: ['beef', 'chicken'],
    faq: [
      {
        title: 'How many meals does this box provide?',
        body:
          'Based on an average 5oz serving size of meat per person, this box would provide 135 servings of delicious American meat',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Do I need a big freezer for the prepper kit?',
        body:
          'Our Prepper Kit includes over 40lbs of meat and is 4 cubic feet while everything is in their individual boxes. However, each piece of meat is individually wrapped, so you are able to unpack everything from boxes to fit in a smaller area. If you take everything out of the boxes, it will take up about 2 cubic feet.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'How long can I store Good Ranchers meat in my freezer?',
        body:
          'You can store everything in your freezer for up to one year. Our vacuum seal packing helps our meat stay safe and high quality for longer than grocery store meat.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What is the lean-to-fat content in the signature beef blend?',
        body:
          'Our blend is an 80/20 ratio that gives tons of rich flavor in whatever dish you use it in.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    mealsDonated: savings.extraLargeBox.meals,
    timeSaved: savings.extraLargeBox.time,
    maxAddOns: 4,
    contentsVolume: 20,
  },
  '127': {
    displayName: 'The Burger Box',
    sku: '4946151',
    options: ['ONETIME_PURCHASE', '4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 30,
    contents: [
      '9lbs American Wagyu Ground Beef',
      'Your New Favorite Burger, Taco, Meatball, Etc.',
      'Stretchy Pants To Put On After You Eat 3 Of Them (Not Really)',
    ],
    description:
      "THE BURGER BOX IS BACK! It sold out in 10 days last time, but we're stocked and ready for your order and subscription. Get 9 pounds of American Wagyu Ground Beef that will blow the last ground beef you had out of the water. Plus, you can use our ground wagyu to elevate all of your meals. Wagyu taco tuesday? Spaghetti and wagyu meatballs? The options and flavors are limitless! Get yours today and try the most popular burger box around.",

    tags: ['beef'],
    faq: [
      {
        title: 'Why don’t the burgers come individually wrapped anymore?',
        body:
          'By changing the packaging, we’re not only reducing plastic waste, but we’re also expediting the production of our burgers so we can ship delicious U.S. burgers to more Americans quickly and efficiently.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'How many burgers come in a box?',
        body:
          'You get 9 pounds of our American Wagyu Ground Beef, which we like to form into patties that are each a third of a pound. If you’d like to make them bigger or smaller, that’s up to you!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What is the lean-to-fat content in the Wagyu burgers?',
        body:
          'We have a proprietary blend in our wagyu burgers, but the fat content is between 20-25% in these burgers.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'What is Wagyu?',
        body:
          'Wagyu is a breed of cattle that originates from Japan. It’s known for its unique marbling and rich flavor. We use American wagyu  cattle that are raised here on local ranches. The experience of American wagyu is one you have to try at least once.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    hasDeal: true,
    dealInfo:
      'Get Two 12oz Packs of Applewood Smoked Bacon For Free with code BURGERBUNDLE.',
    dealCoupon: 'BURGERBUNDLE',
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 4,
    contentsVolume: 3,
  },
  '129': {
    displayName: 'The Whole Chicken Box',
    sku: '4915031',
    options: ['4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 50,
    contents: [
      '8 (4.5oz) Plain Chicken Breasts',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
      '8 (5oz) Boneless, Skinless Chicken Thighs',
      '5 (8oz) Semi Boneless Chicken Leg Quarters',
      '2 (1lb) Chicken Lollipop Packs (Chicken Drumsticks That Have Been Trimmed)',
    ],
    description:
      "You asked for it, we made it! A box full of America's best chicken with no seasoning at all. This is chicken that anyone can enjoy and love. Our limited edition Whole Chicken box is one you have to try! Pasture raised, no antibiotics EVER, no hormones, and raised in a way that is better than the organic standard. Don't miss out on your chance to try this exclusive product! Our whole chicken box is chicken done the right way.",

    tags: ['chicken'],
    faq: [
      {
        title: 'How is this chicken better than Organic?',
        body:
          'We don’t aim to earn a label, but rather to earn your trust. Our chicken comes from a NAE (No Antibiotics Ever) program, which means they are free of antibiotics and added hormones their entire lives. That coupled with how tender and easy to prepare our chicken is, are just some of the reasons why we are truly better than organic.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'How is each cut of meat in the box packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control very easy.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Is this all plain chicken with no seasoning?',
        body:
          'Yes, our Whole Chicken Box comes with our unseasoned chicken ready for you to season and cook how you want. It’s the perfect, delicious canvas for tasty and juicy meals!',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: null,
    stickerTitle: null,
    stickerColor: null,
    stickerTextColor: null,
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
  },
  // '136': {
  //   displayName: 'Easy Meal Prep Box',
  //   sku: '4974379',
  //   options: ['ONETIME_PURCHASE'],
  //   heading: 'Make Healthy Simple With',
  //   subHeadings: [
  //     '100% American Meat',
  //     'Free Express Shipping',
  //     'Transparent and Trustworthy Processes',
  //     'Food You’re Excited To Eat',
  //   ],
  //   meals: 30,
  //   contents: [
  //     '10 (10oz) Boneless NY Strip Steaks (USDA Prime or Upper Choice)',
  //     '10 (4.5oz) Plain “Better Than Organic” Chicken Breasts',
  //     'Individually packaged and vacuum sealed cuts for quick defrosting and easy portioning',
  //   ],
  //   description:
  //     'Our easy meal prep box does exactly what the name says. It makes healthy meal prep easy and delicious! We remove the hassle of finding great meat at the store—which is hard to do, and we take care of trimming every piece to save you time. All the beef and chicken is born, raised, and made in America from local farms with exceptional standards. Each piece is hand-trimmed by expert butchers to make cooking it easy and enjoying it even easier. Start 2023 right with our easy meal prep box today! Only available for one-time purchase—Limited Supply.',

  //   tags: ['beef', 'chicken'],
  //   faq: [
  //     {
  //       title: 'How is this chicken better than Organic?',
  //       body:
  //         'Organic chicken in the store is not as good as you think. The label of “Organic” really only refers to the feed the chicken eats in its life and not the environment it lives in daily. We go beyond feed to make the whole process better than the organic standard, which is why our chicken blows people away.',
  //       btnText: 'Visit Our About Page',
  //       btnLink: 'about#sourcing',
  //     },
  //     {
  //       title: 'Are your cattle grass-fed?',
  //       body:
  //         'Yes, all of our cows are grass-fed. They are not grass-finished but are instead grain-finished to make sure we consistently produce the best beef in America. You can read all about our sourcing on our about page.',
  //       btnText: 'Visit Our About Page',
  //       btnLink: 'about#sourcing',
  //     },
  //     {
  //       title: 'Are your steaks aged?',
  //       body:
  //         'Yes, we age our steaks a minimum of 21 days to help them reach the peak of tenderness. That’s why our steaks are consistently more tender and delicious than other choices in the market.',
  //       btnText: 'Visit Our Help Center',
  //       btnLink: 'help-center',
  //     },
  //     {
  //       title: 'What does USDA Prime & Upper Choice mean?',
  //       body:
  //         'These are two grades of beef from the USDA. The grade refers to the quality of marbling, color, and age of the beef. Prime and Upper Choice are the highest and most sought-after grades.',
  //       btnText: 'Visit Our Help Center',
  //       btnLink: 'help-center',
  //     },
  //   ],
  //   sticker: true,
  //   stickerTitle: 'Latest Release',
  //   stickerColor: '#9ABEAA',
  //   stickerTextColor: 'night-800',
  //   mealsDonated: savings.smallBox.meals,
  //   timeSaved: savings.smallBox.time,
  // },
  '143': {
    displayName: 'Prime Pork Box',
    sku: '8675309',
    options: ['ONETIME_PURCHASE, 4_WEEKS, 6_WEEKS, 8_WEEKS'],
    meals: 30,
    contents: [
      '2 (14oz) Bone-In Steakhouse Pork Chops',
      '4 (8oz) Boneless Pork Chops',
      '2 (16oz) Angus Ground Beef and Pork Belly 50/50 Blend',
      '3 (12oz) Applewood-Smoked Bacon Packs',
      '4 (3oz) Mild Italian Sausages',
      '4 (3oz) Smoked Brats',
    ],
    description:
      'If you like pork, then you’ll love ours! Our Prime Pork Box is full of the best pork America has to offer. Not only is our Prime Pork heritage-breed, but it’s all humanely raised and sourced from U.S. family farms. Enjoy tender pork chops, flavorful pork-belly burgers, applewood bacon, and the tastiest of brats in every box. If you’ve never experienced the superior marbling and quality of our Prime Pork, then you’ve never had pork at its finest.',
    tags: ['pork'],
    faq: [
      {
        title: 'What is Prime Pork?',
        body:
          'Similar to Prime beef (which we also sell), Prime pork is pork that possesses superior marbling, has a visible pink color and is 25% more tender than other pork. It’s simply the best pork in America.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'Where does your pork come from?',
        body:
          'Every single cut of Prime Pork is sourced from American family farms who are committed to animal wellness, sustainable farming practices, and producing seriously delicious pork.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'How is it packaged?',
        body:
          'Every cut is individually wrapped and then flash frozen to lock in freshness and preserve the quality. This makes it easy to store, thaw, and cook exactly what you need for dinner while saving the rest.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Is your pork raised crate free?',
        body:
          'Yes, we’re proud to source pork that is verified by the USDA to be humanely raised. That means we never use any crates or confinement during the life of the animal. Our Prime Pork is raised with the animal in mind.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 1,
    contentsVolume: 6,
  },
  '147': {
    displayName: 'Wild-Caught Seafood Box',
    sku: 'SEAFOODBOX',
    options: ['ONETIME_PURCHASE, 4_WEEKS, 6_WEEKS, 8_WEEKS'],
    meals: 30,
    contents: [
      '2 (6oz) Pacific Cod',
      '2 (6oz) Alaskan Halibut',
      '6 (6oz) King Salmon or Sockeye Salmon',
      '2 (1lb) Gulf Shrimp Packs (Peeled and Deveined)',
    ],
    description:
      "If you're a seafood lover, then you're going to be hooked! Not only is everything wild-caught, but it's sustainably sourced from certified fisheries along our coasts. Enjoy mouth-watering salmon filets, succulent shrimp, sensational halibut, and exceptional cod that will all truly make a splash at your dinner table. This is America’s best seafood delivered right to your door.",
    tags: ['seafood'],
    faq: [
      {
        title: 'What is Wild Caught Seafood?',
        body:
          'Wild Caught Seafood refers to seafood that has been caught directly from its natural habitat, like oceans, seas or freshwater bodies. It ensures the highest quality seafood, full of authentic flavor and texture, as the animals are allowed to grow and develop in their native environment.',
        btnText: 'Visit Our About Page',
        btnLink: 'about',
      },
      {
        title: 'Where does your seafood come from?',
        body:
          'All our seafood is responsibly sourced from certified fisheries across America. These local fisheries are known for their sustainable practices, ensuring the health of our oceans and rivers, and commitment to delivering seriously delicious seafood.',
        btnText: 'Visit Our About Page',
        btnLink: 'about#sourcing',
      },
      {
        title: 'How is it packaged?',
        body:
          "Each piece of seafood is individually wrapped and then flash-frozen. This process helps to retain the seafood's freshness and quality, making it easy for you to store, thaw, and cook exactly what you need for your meal while keeping the rest fresh.",
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
      {
        title: 'Why is your seafood so delicious?',
        body:
          'Our quality is truly superior. We source restaurant quality cuts from fisheries that know the waters like the back of their hand. By tapping into generations of expertise, we are able to consistently provide you with the best seafood experience America has to offer.',
        btnText: 'Visit Our Help Center',
        btnLink: 'help-center',
      },
    ],
    sticker: false,
    stickerTitle: 'Latest Release',
    stickerColor: '#285780',
    stickerTextColor: 'white',
    mealsDonated: savings.smallBox.meals,
    timeSaved: savings.smallBox.time,
    maxAddOns: 2,
    contentsVolume: 5,
  },
  '241': {
    gifting: true,
    displayName: "America's Best Burger Box",
    sku: 'GRBDBB',
    options: ['ONETIME_PURCHASE'],
    meals: 30,
    tags: [],
    contents: [
      '16 (6oz) American Wagyu + Angus Burgers',
      '1 (14oz) Jar of Bacon Butter',
    ],
    shortDescription:
      'Give the gift of burger perfection with 16 American Wagyu and Angus burgers and a jar of our brand new Bacon Butter.',
    description:
      "Give the gift of burger perfection with 16 mouthwatering and buttery American Wagyu and Angus burgers, sourced from local, independent farms. Each burger is a flavor-packed masterpiece that's sure to delight the taste buds of your loved ones. To elevate their burger game even further, we've included our signature Bacon Butter – a delectable topping that adds a smoky, savory twist to every bite. It's the secret ingredient that turns any burger into a gourmet delight.",
    faq: [
      {
        title: 'Why should I choose Good Ranchers as a gift?',
        body:
          'We’re the #1 All-american meat delivery service. We have over 20,000 5-star reviews on our boxes because our quality of product and service is unmatched. When you gift America’s best meat, you can be confident that whoever you send it to will love it. Each cut is 100% American, steakhouse-quality, transparently sourced, and backed by a Satisfaction Guarantee. Rest assured, when you gift with us, we make sure the experience is a cut above the rest—no matter what.',
      },
      {
        title: 'When will it arrive / How long does delivery take?',
        body:
          'Orders take up to 5-7 days to process and then once shipped, arrive at their destination in another 2-3 days time. During peak holiday times, we require more time to guarantee delivery by a certain date. If you’re looking to get delivery by Christmas, you would need to place your order by December 10th. After that, we cannot guarantee delivery before Christmas Day.',
      },
      {
        title: 'How is the gift packaged?',
        body:
          'Each gift box is packed in our insulated shipper with dry ice to ensure it stays cold during transit. Each cut of meat is individually-wrapped and then packed in a resealable bag for easy storage and organization in the freezer or refrigerator. Our box and insulation are fully recyclable, so the gift is easy to receive, unpack, and enjoy!',
      },
    ],
    heading: 'Impress Them With:',
    subHeadings: [
      '100% American Meat',
      'Free Express Shipping',
      'Transparent and Trustworthy Processes',
      "A Gift They'll Truly Savor",
    ],
    contentsVolume: 3,
    maxAddOns: 4,
    isNov24: true,
    sticker: true,
  },
  '242': {
    gifting: true,
    displayName: 'Ranchers Favorites Gift Box',
    sku: 'GRFAVORITES',
    options: ['ONETIME_PURCHASE'],
    meals: 30,
    tags: [],
    contents: [
      '2 (12oz) Applewood-Smoked Bacon Packs',
      '2 (14oz) Bone-In Steakhouse Pork Chops',
      '3 (10oz) NY Strip Steaks',
      '4 (3oz) Smoked-Bratwursts',
      '8 (4.5oz) Boneless, Skinless Chicken Breasts',
    ],
    shortDescription:
      'Gift our best selling NY Strip Steaks, tender Pork Chops, savory Bacon, Smoked Bratwursts, and pre-trimmed Chicken Breasts today!',
    description:
      "This gift box includes an assortment of our best-selling cuts, offering a taste of America’s best meats. Give the gift of perfectly aged NY Strip Steaks, tender Pork Chops, savory Bacon, flavorful Sausages, and premium Chicken Breasts – all sourced from local, independent farms dedicated to exceptional quality. Whether they're grilling up a steakhouse-worthy dinner, savoring breakfast with our bacon and sausages, or preparing a hearty chicken meal, this gift box has it all. The items included in this box have thousands of 5-star reviews, which is why this is a gift that's sure to leave a lasting impression!",
    faq: [
      {
        title: 'Why should I choose Good Ranchers as a gift?',
        body:
          'We’re the #1 All-american meat delivery service. We have over 20,000 5-star reviews on our boxes because our quality of product and service is unmatched. When you gift America’s best meat, you can be confident that whoever you send it to will love it. Each cut is 100% American, steakhouse-quality, transparently sourced, and backed by a Satisfaction Guarantee. Rest assured, when you gift with us, we make sure the experience is a cut above the rest—no matter what.',
      },
      {
        title: 'When will it arrive / How long does delivery take?',
        body:
          'Orders take up to 5-7 days to process and then once shipped, arrive at their destination in another 2-3 days time. During peak holiday times, we require more time to guarantee delivery by a certain date. If you’re looking to get delivery by Christmas, you would need to place your order by December 10th. After that, we cannot guarantee delivery before Christmas Day.',
      },
      {
        title: 'How is the gift packaged?',
        body:
          'Each gift box is packed in our insulated shipper with dry ice to ensure it stays cold during transit. Each cut of meat is individually-wrapped and then packed in a resealable bag for easy storage and organization in the freezer or refrigerator. Our box and insulation are fully recyclable, so the gift is easy to receive, unpack, and enjoy!',
      },
    ],
    heading: 'Impress Them With:',
    subHeadings: [
      '100% American Meat',
      'Free Express Shipping',
      'Transparent and Trustworthy Processes',
      "A Gift They'll Truly Savor",
    ],
    contentsVolume: 5,
    maxAddOns: 2,
    isNov24: true,
    sticker: true,
    stickerTitle: '#1 Most Gifted',
    stickerColor: '#BD472A',
    stickerTextColor: 'white',
  },
  '243': {
    gifting: true,
    displayName: 'Signature Steak Gift Box',
    sku: 'GRPRIMESELECT',
    options: ['ONETIME_PURCHASE'],
    meals: 30,
    tags: [],
    contents: [
      '3 (10oz) NY Strip Steaks',
      '2 (18oz) Bone-In Ribeyes',
      '2 (17oz) T-Bones',
    ],
    shortDescription:
      'Surprise someone with our signature, hand-cut Bone-in Ribeyes, NY Strip Steaks, and Boneless Ribeye Steaks.',
    description:
      "Gift the ultimate steak lover's experience with our Signature Steak Gift Box. This gift box features the finest T-Bones, Bone-In Ribeye Steaks, and Boneless NY Strip Steaks, each aged to perfection for 21 days and hand-cut to ensure steakhouse-quality excellence. Elevate your gifting with the rich flavors and unmatched tenderness of our signature steaks. Whether it's a special occasion or a treat for yourself, the Signature Steak Gift Box is the perfect choice for those who appreciate the very best in American meat.",
    faq: [
      {
        title: 'Why should I choose Good Ranchers as a gift?',
        body:
          'We’re the #1 All-american meat delivery service. We have over 20,000 5-star reviews on our boxes because our quality of product and service is unmatched. When you gift America’s best meat, you can be confident that whoever you send it to will love it. Each cut is 100% American, steakhouse-quality, transparently sourced, and backed by a Satisfaction Guarantee. Rest assured, when you gift with us, we make sure the experience is a cut above the rest—no matter what.',
      },
      {
        title: 'When will it arrive / How long does delivery take?',
        body:
          'Orders take up to 5-7 days to process and then once shipped, arrive at their destination in another 2-3 days time. During peak holiday times, we require more time to guarantee delivery by a certain date. If you’re looking to get delivery by Christmas, you would need to place your order by December 10th. After that, we cannot guarantee delivery before Christmas Day.',
      },
      {
        title: 'How is the gift packaged?',
        body:
          'Each gift box is packed in our insulated shipper with dry ice to ensure it stays cold during transit. Each cut of meat is individually-wrapped and then packed in a resealable bag for easy storage and organization in the freezer or refrigerator. Our box and insulation are fully recyclable, so the gift is easy to receive, unpack, and enjoy!',
      },
    ],
    heading: 'Impress Them With:',
    subHeadings: [
      '100% American Meat',
      'Free Express Shipping',
      'Transparent and Trustworthy Processes',
      "A Gift They'll Truly Savor",
    ],
    contentsVolume: 3,
    maxAddOns: 4,
    isNov24: true,
    sticker: true,
  },
  '251': {
    gifting: true,
    displayName: 'Weekly Essentials Box',
    sku: 'WEEKLYESSENTIALS',
    options: ['2_WEEKS, 4_WEEKS, 6_WEEKS'],
    meals: 30,
    tags: ['beef', 'chicken'],
    contents: [
      '8 (4.5oz) Pre-Trimmed Plain Chicken Breasts',
      '4 (1lb) Angus Ground Beef Packs',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
      '2 (1lb) Sirloin Steak Tip Packs',
    ],
    description:
      'Take the chaos out of shopping and the stress out of meal prep with our weekly essentials! You can skip the meat aisle and get pre-trimmed cuts of beef and chicken that make preparing your weekly meals a breeze. Every pack of chicken, steak, and beef is sourced from American farms and ranches. You care about what your family eats, so do we. That’s why you should start 2024 stocked up with essentials!',
    faq: [
      {
        title: 'How many meals are in this box?',
        body:
          'There are 33 5 oz servings of high-quality beef and chicken in the Weekly Essentials Box.',
      },
      {
        title: 'What frequency should I choose?',
        body:
          'It depends on family size and how often you cook at home, but if you’re unsure we recommend starting at the middle frequency option of 4 weeks. You can always adjust your frequency in your account once you’ve worked through part of your first box and have a better idea of how often you need it.',
      },
      {
        title: 'How is your chicken different?',
        body:
          'Our quality is truly superior. We don’t aim to earn a label, but rather to earn your trust. Our chicken comes from a NAE (No Antibiotics Ever) program, which means they are free of antibiotics and added hormones their entire lives. That coupled with how tender and easy to prepare our chicken is, are just some of the reasons why we are truly better than organic.',
      },
      {
        title: 'How is each cut packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to store it for up to a year and makes meal prep, thawing, and portion control very easy.',
      },
    ],
    contentsVolume: 5,
    maxAddOns: 2,
    sticker: false,
    stickerTitle: '',
    stickerColor: '',
    stickerTextColor: '',
  },
  '254': {
    displayName: 'Game Day Box',
    displayNameLarge: 'Game Day Pro Box',
    sku: 'GAMEDAYSMALL',
    options: ['ONETIME_PURCHASE'],
    meals: 30,
    tags: ['beef', 'chicken', 'pork'],
    contents: [
      '8 (3oz) Smoked Brats',
      '2 (1lb) Signature Angus Ground Beef Packs',
      '2 (1lb) Jumbo Chicken Wing Packs',
      '2 (1lb) Strip-Cut Plain Chicken Packs',
      'FREE GIFT: 1 (14oz) Jar of Bacon Butter',
    ],
    largeContents: [
      '8 (3oz) Smoked Brats',
      '4 (1lb) Signature Angus Ground Beef Packs',
      '6 (1lb) Jumbo Chicken Wing Packs',
      '4 (1lb) Strip-Cut Plain Chicken Packs',
      '8 (3oz) Mild Italian Sausages',
      '8 (6oz) American Wagyu + Angus Burgers',
      'FREE GIFT: 1 (14oz) Jar of Bacon Butter',
    ],
    description:
      'Kick off your game day by scoring big with your taste buds! Our Game Day box features everything you need for huddling up with family, cheering on your team, and enjoying a Hall-of-Fame starting line-up of cuts. Enjoy burgers, chili, tacos, sausages, wings and more! This box has the ingredients you need to make a game day spread that will have everyone cheering for more. Don’t fumble the chance to savor your game day experience—order now and gear up for the All-American feast!',
    descriptionLarge:
      'Kick off your game day by scoring big with your taste buds! Our Game Day Pro Box features everything you need for those big gatherings—whether it’s for hosting a watch party, a backyard bash, or a pre-game tailgate. Enjoy wagyu burgers, chili, tacos, sausages, wings and more! This box has the ingredients you need to make a game day spread that will have everyone cheering for more. Don’t fumble the chance to savor your game day experience—order now and gear up for the All-American feast!',
    faq: [
      {
        title: 'What’s the Game Day Pro Option?',
        body:
          "Our Game Day Pro option is a beefed up option of our regular Game Day Box! Whether you're hosting a watch party, a backyard bash, or a pre-game tailgate, this box ensures there's enough meat to satisfy every appetite. With double the quantity and the exciting addition of Italian Sausages and Wagyu Patties, our Game Day Pro Box guarantees double the fun for cheering on your favorite team. So, if you’re looking to level up your game day experience—look no further than the Game Day Pro!",
      },

      {
        title: 'What are chicken lollipops?        ',
        body:
          'Our Chicken Lollipops are drumsticks that have been frenched, which moves the meat towards the top of the bone and trims it to perfection so that eating is easy and enjoying is even easier. They’re amazing grilled or fried and tossed or brushed with your favorite game day wing sauces.',
      },
      {
        title: 'How is each cut packaged?',
        body:
          'Every piece is vacuum sealed and flash-frozen. This is the safest way for us to package the meat. It allows you to easily thaw it for game day and then cook exactly what you need!',
      },
    ],
    contentsVolume: 5,
    maxAddOns: 2,
    sticker: true,
    stickerTitle: 'Free \n Bacon \n Butter',
    stickerColor: '#FF8D6D',
    stickerTextColor: 'night-800',
    heading: 'Score Big On Game Day With',
    subHeadings: [
      '100% American Meat',
      'Free Express Shipping',
      'Transparent and Trustworthy Processes',
      'Food You’re Excited To Eat',
    ],
    hasLargeOptions: true,
  },
  '255': {
    displayName: 'Surf & Turf Box',
    sku: 'SURFTURF',
    options: ['4_WEEKS', '6_WEEKS', '8_WEEKS'],
    meals: 30,
    contents: [
      '3 (10oz) Black Angus N.Y. Strips',
      '4 (18oz) Bone-in Ribeyes or (16oz) Boneless Ribeyes',
      '2 (6oz) Alaskan Halibut Filets',
      '2 (1lb) Gulf Shrimp Packs (Peeled and Deveined)',
    ],
    description:
      'Dive into the ultimate culinary adventure with our new Surf & Turf box! From sea to shining steak, this new box is perfect for grilling enthusiasts and seafood lovers alike. Featuring succulent New York Strip Steaks, perfectly marbled Bone-in Ribeyes, delicate Wild-Caught Halibut, and shrimply delightful Wild-Caught Gulf Shrimp—each cut is a masterpiece, representing a sea of flavor that you can’t find elsewhere. To elevate your dining experience and enjoy the best of the sea and steak, dive into this box today! We promise you’ll be hooked on every bite.',

    tags: ['beef', 'seafood'],
    faq: [
      {
        title: 'ARE THESE STEAKS AGED?',
        body:
          'Yes, we age our steaks a minimum of 21 days to help them reach the peak of tenderness. That’s why our steaks are consistently more tender and delicious than other choices in the market.',
      },
      {
        title: 'WHAT IS BLACK ANGUS?',
        body:
          'Black Angus is a specific breed of cattle, very often used in steakhouses for its coveted flavor and amazing marbling. When you’re getting USDA prime and Upper Choice Black Angus, you’re getting some of the best beef on the market.',
      },
      {
        title: 'WHAT IS WILD-CAUGHT SEAFOOD?',
        body:
          'Wild Caught Seafood refers to seafood that has been caught directly from its natural habitat, like oceans, seas or freshwater bodies. It ensures the highest quality seafood, full of authentic flavor and texture, as the animals are allowed to grow and develop in their native environment.',
      },
      {
        title: 'HOW IS IT PACKAGED?',
        body:
          'Each of our seafood and steak products are individually wrapped, vacuum sealed, and flash-frozen. This process helps to retain the freshness and quality, making it easy for you to store, thaw, and cook exactly what you need for your meal while keeping the rest fresh.',
      },
    ],
    contentsVolume: 5,
    maxAddOns: 2,
    sticker: true,
    stickerTitle: 'Latest Release',
    stickerColor: '#9ABEAA',
    stickerTextColor: 'night-800',
    subHeadings: [
      'Save $25 on Every Box',
      'Bonus Cut Every 3rd Order',
      'Free Express Shipping',
      'Access to Exclusive Offers',
    ],
  },
}
