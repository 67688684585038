export interface InitialStateType {
  drawerOpen: boolean
  lightContent: boolean
  overlayActive: boolean
  overlayLocked: boolean
  cartCacheBuster: number
  Modal: {
    modal: React.FunctionComponent
    locked?: boolean
    onClose?: (...rest: any[]) => unknown
  } | null
}

export const initialState: InitialStateType = {
  drawerOpen: false,
  lightContent: true,
  overlayActive: false,
  overlayLocked: false,
  cartCacheBuster: 0,
  Modal: null,
}

export const openDrawer = () => {
  return {
    type: 'openDrawer',
  }
}

export const closeDrawer = () => {
  return {
    type: 'closeDrawer',
  }
}

export const setModal = (modal) => {
  return {
    type: 'setModal',
    payload: modal,
  }
}

export const useLightContent = () => {
  return {
    type: 'lightContent',
  }
}

export const useDarkContent = () => {
  return {
    type: 'darkContent',
  }
}

export const addOverlay = (lock?: boolean) => {
  return {
    type: 'addOverlay',
    payload: Boolean(lock),
  }
}

export const removeOverlay = () => {
  return {
    type: 'removeOverlay',
  }
}

export const refreshCart = () => {
  return {
    type: 'refreshCart',
    payload: performance.now(),
  }
}

export default function reducer(
  _state: InitialStateType,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case 'openDrawer':
      return {
        ..._state,
        Modal: null,
        drawerOpen: true,
        overlayActive: true,
      }
    case 'closeDrawer':
      return {
        ..._state,
        drawerOpen: false,
        overlayActive: false,
        Modal: null,
      }
    case 'setModal':
      return {
        ..._state,
        overlayActive: Boolean(action.payload),
        overlayLocked: Boolean(action.payload?.locked),
        Modal: action.payload,
      }
    case 'lightContent':
      return {
        ..._state,
        lightContent: true,
      }
    case 'darkContent':
      return {
        ..._state,
        lightContent: false,
      }
    case 'addOverlay':
      return {
        ..._state,
        overlayActive: true,
        overlayLocked: action.payload,
      }
    case 'removeOverlay':
      return {
        ..._state,
        overlayActive: false,
        overlayLocked: false,
      }
    case 'refreshCart':
      return {
        ..._state,
        cartCacheBuster: action.payload,
      }
    default:
      throw new Error()
  }
}
