export const hamIds = [
  'Spiral-Cut Heritage Ham (Sub)',
  'Spiral-Cut Heritage Ham (OTP)',
]

export const bundles = {
  'Holiday Ham': {
    sortOrder: 0,
    id: 'Holiday Ham',
    live: false,
    limit: 1,
    name: 'Spiral-Cut Heritage Ham',
    delimiter: 1,
    volume: 3,
    description:
      ' Get your holiday ham straight from American farms! Our spiral-cut hams are approximately 9lbs, serve 16-20, and come with a signature glaze. Make your holiday season easy & delicious!',
    price: `99`,
    oldPrice: '$119',
    totalPrice: 99.99,
    sticker: true,
    stickerColor: '#BD472A',
    stickerText: 'Special Holiday \n Offer',
    stickerTextColor: 'white',
    thumbnail: '/img/rebrand/hamHD.webp',
    disclaimer: '*Limit of one per order',
    items: [
      {
        id: '233',
        quantity: 1,
        variant_id: 0,
        external_id: '233_HOLHAM23',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/233/images/815/ham-copy_1__37749.1696882429.386.513.jpg?c=1',
    ],
  },
  'Spiral-Cut Heritage Ham (OTP)': {
    sortOrder: 0,
    otp: true,
    id: 'Spiral-Cut Heritage Ham (OTP)',
    live: false,
    limit: 1,
    name: 'Spiral-Cut Heritage Ham',
    delimiter: 1,
    volume: 3,
    description:
      ' Get your ham straight from American farms! Our spiral-cut hams are approximately 9lbs, serve 16-20, and come with a signature glaze. Make your holiday season easy & delicious!',
    price: `59`,
    oldPrice: '$119',
    totalPrice: 59.99,
    subscriberPrice: 59.99,
    subscriberDiscount: '50%',
    subscriberStickerText: '50% OFF',
    sticker: true,
    stickerColor: '#BD472A',
    stickerText: '50% OFF',
    stickerTextColor: 'white',
    thumbnail: '/img/rebrand/hamHD.webp',
    disclaimer: false,
    items: [
      {
        id: '259',
        quantity: 1,
        external_id: '259_OTPHAM24',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/233/images/815/ham-copy_1__37749.1696882429.386.513.jpg?c=1',
    ],
  },
  'Spiral-Cut Heritage Ham (Sub)': {
    sortOrder: 0,
    subOnly: true,
    id: 'Spiral-Cut Heritage Ham (Sub)',
    live: false,
    limit: 1,
    name: 'Spiral-Cut Heritage Ham',
    delimiter: 1,
    volume: 3,
    description:
      ' Get your ham straight from American farms! Our spiral-cut hams are approximately 9lbs, serve 16-20, and come with a signature glaze. Make your holiday season easy & delicious!',
    price: `19`,
    oldPrice: '$119',
    totalPrice: 59.99,
    subscriberPrice: 19.99,
    subscriberDiscount: '33%',
    subscriberStickerText: '83% OFF',
    sticker: true,
    stickerColor: '#BD472A',
    stickerText: '83% OFF',
    stickerTextColor: 'white',
    thumbnail: '/img/rebrand/hamHD.webp',
    disclaimer: false,
    items: [
      {
        id: '258',
        quantity: 1,
        external_id: '258_SUBHAM24',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/233/images/815/ham-copy_1__37749.1696882429.386.513.jpg?c=1',
    ],
  },
  'Applewood Bacon Combo': {
    sortOrder: 5,
    id: 'Applewood Bacon Combo',
    live: true,
    name: 'Applewood Bacon',
    delimiter: 2,
    volume: 1,
    description:
      'Our Applewood Bacon is thickly cut and the perfect addition for every meal. Enjoy two, 12oz packs of 100% American bacon.      ',
    price: `19.98`,
    totalPrice: 19.98,
    sticker: false,
    thumbnail: '/img/rebrand/Bacon02-Square.webp',
    disclaimer: false,
    subscriberDisclaimer: '$9.99/ea ',
    items: [
      {
        id: '140',
        quantity: 2,
        external_id: '140_4987751-A',
      },
    ],
    images: ['/img/rebrand/Bacon02-Square.webp'],
  },
  'Bone-In Ribeye': {
    sortOrder: 9,
    id: 'Bone-In Ribeye',
    live: true,
    limit: 9999,
    name: 'Bone-In Ribeyes',
    delimiter: 1,
    volume: 1,
    description:
      '2 (18oz) BONE-IN RIBEYES: Aged for 21 days, hand-cut, tender, and marbled with unforgettable flavor, our Black Angus ribeyes are sure to wow your tastebuds. USDA Prime or Upper Choice, every bite of these ribeyes is better than the last.',
    price: '54.99',
    oldPrice: '$84.99',
    totalPrice: 54.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/234/images/862/Ribeye__90976.1697129448.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '234',
        quantity: 1,
        external_id: '234_BF-RIBEYE',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/234/images/862/Ribeye__90976.1697129448.386.513.jpg?c=1',
    ],
  },
  'NY Strips': {
    sortOrder: 2,
    id: 'NY Strips',
    live: true,
    limit: 9999,
    name: 'NY Strip Steaks',
    delimiter: 1,
    volume: 1,
    description:
      '3 (10oz) NY STRIP STEAKS: Our Prime or Upper Choice Black Angus New York Strips are so good, that with one bite, you’ll have an empire steak of mind. Add three, perfectly aged, steakhouse-quality N.Y. Strips that are a cut above the rest to your order!',
    price: '49.99',
    oldPrice: '$79.99',
    totalPrice: 49.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/235/images/874/NYStrip_1__17295.1698086490.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '235',
        quantity: 1,
        variant_id: 0,
        external_id: '235_BF-NY-STRIP',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/235/images/863/NYStrip__79629.1697129601.386.513.jpg?c=1',
    ],
  },
  'Ground Beef': {
    sortOrder: 8,
    id: 'Ground Beef',
    live: true,
    limit: 9999,
    name: 'Angus Ground Beef',
    delimiter: 1,
    volume: 1,
    description:
      '2 (1lb) ANGUS GROUND BEEF packs: All-natural, vegetarian fed, and made from 100% American cattle, our Angus ground beef is so good, it’s ground-breaking. Plus, with zero hormones or antibiotics, you’ll be getting only the best of the best.',
    price: '16.99',
    oldPrice: '$24.99',
    totalPrice: 16.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/236/images/864/GroundBeef__86199.1697129653.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '236',
        quantity: 1,
        variant_id: 0,
        external_id: '236_BF-GRND-ANGUS',
      },
    ],
    images: [
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/236/images/864/GroundBeef__86199.1697129653.386.513.jpg?c=1',
    ],
  },
  Brats: {
    sortOrder: 11,
    id: 'Brats',
    live: true,
    limit: 9999,
    name: 'Smoked Brats',
    delimiter: 1,
    volume: 1,
    description:
      '4 (3oz) SMOKED-BRATS: Smoked to perfection, every bratwurst is a testament to our prime pork’s quality. Flash frozen to preserve its freshness, our bratwurst is the best on the market. It’s so versatile, you can use it to make any dish a hit with ease.',
    price: '9.99',
    oldPrice: '$14.99',
    totalPrice: 9.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/237/images/929/gr-essentials-34_2__75562.1704482848.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '237',
        quantity: 1,
        variant_id: 0,
        external_id: '237_PRK-BRAT-SUSG',
      },
    ],
    images:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/237/images/866/HolidayHelper-3__24966.1697138094.386.513.jpg?c=1&_gl=1*1iq08ut*_ga*MTAyMzg1NTg0OC4xNjg1MDQ2NTgw*_ga_WS2VZYPC6G*MTY5NzEzOTQ3Mi42My4wLjE2OTcxMzk0OTIuNDAuMC4w',
  },
  'Italian Sausage': {
    sortOrder: 12,
    id: 'Italian Sausage',
    live: true,
    limit: 9999,
    name: 'Mild-Italian Sausage',
    delimiter: 1,
    volume: 1,
    description:
      '4 (3oz) MILD-ITALIAN SAUSAGE: Our Italian sausage is made from prime pork, which is humanely raised, crate-free, and 25% more tender than other pork. Infused with classic Italian spices, the flavor of each link is rich and full due to the marbling in our pork.',
    price: '9.99',
    oldPrice: '$14,99',
    totalPrice: 9.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/238/images/928/gr-essentials-35_1__96300.1704482734.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '238',
        quantity: 1,
        variant_id: 0,
        external_id: '238_PRK-ITL-SUSG',
      },
    ],
    images:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/238/images/867/HolidayHelper-1__00381.1697138210.386.513.jpg?c=1',
  },
  'American Wagyu & Angus Burgers': {
    sortOrder: 3,
    id: 'American Wagyu & Angus Burgers',
    live: true,
    limit: 9999,
    name: 'American Wagyu & Angus Burgers',
    delimiter: 1,
    volume: 1,
    description:
      '8 (6oz) AMERICAN WAGYU & ANGUS BURGERS: Get your hands on American Wagyu & Angus burgers: made with a proprietary blend that has unparalleled marbling and flavor. Craft a burger that’s sure to impress with this rich, buttery wagyu & angus blend you can’t get anywhere else. ',
    price: '29.99',
    oldPrice: '$34.99',
    totalPrice: 29.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/239/images/868/BurgerBox__17012.1697550283.386.513.jpg?c=1&_gl=1*ludk6o*_ga*MTAyMzg1NTg0OC4xNjg1MDQ2NTgw*_ga_WS2VZYPC6G*MTY5NzU2OTM1Ny42NS4wLjE2OTc1NjkzNjQuNTMuMC4w',
    disclaimer: '',
    items: [
      {
        id: '239',
        quantity: 1,
        variant_id: 0,
        external_id: '239_BF-WAGYU-PAT',
      },
    ],
    images:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/239/images/868/BurgerBox__17012.1697550283.386.513.jpg?c=1&_gl=1*ludk6o*_ga*MTAyMzg1NTg0OC4xNjg1MDQ2NTgw*_ga_WS2VZYPC6G*MTY5NzU2OTM1Ny42NS4wLjE2OTc1NjkzNjQuNTMuMC4w',
  },
  'Bacon Butter': {
    sortOrder: 7,
    id: 'Bacon Butter',
    live: true,
    limit: 9999,
    name: 'Bacon Butter',
    delimiter: 1,
    volume: 1,
    description:
      'BACON BUTTER (14oz): Your new favorite topping is just one click away. A savory spread unlike any other, our Bacon Butter combines the smoky deliciousness of our Applewood Bacon with a velvety, jam-like texture. Try to find something it isn’t delicious on, we dare you.',
    price: '14.99',
    oldPrice: '$19.99',
    totalPrice: 14.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail: '/img/rebrand/BaconButter.webp',
    disclaimer: '',
    items: [
      {
        id: '240',
        quantity: 1,
        variant_id: 0,
        external_id: '240_BACONBUTTER',
      },
    ],
    images: '',
  },
  'Bone in Pork Chops': {
    sortOrder: 13,
    id: 'Bone in Pork Chops',
    live: true,
    limit: 9999,
    name: ' Bone-In Steakhouse Pork Chops',
    delimiter: 1,
    volume: 1,
    description:
      '2 (14oz) Steakhouse Cut Pork Chops: Primed and ready for your enjoyment. Taste the difference with our Thick-Cut Pork Chops that come from heritage-breed, humanely raised, prime pork. That means no antibiotics, zero added hormones, superior marbling, and our trichinosis-free guarantee.',
    price: '19.99',
    oldPrice: '$24.99',
    totalPrice: 19.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/247/images/888/Good_Ranchers-416__30694.1698327376.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '247',
        quantity: 1,
        variant_id: 0,
        external_id: '247_PRK-BNIN',
      },
    ],
  },
  'Pumpkin Spice Bacon Combo': {
    id: 'Pumpkin Spice Bacon Combo',
    sortOrder: 0,
    live: false,
    limit: 9999,
    name: 'Pumpkin Spice Bacon',
    delimiter: 2,
    volume: 1,
    description:
      'Try this limited-time bacon before it’s gone! It’s a fall special that’s sure to impress and leave you pumpkin spice obsessed. Get two, 12oz packs of Pumpkin Spice Bacon today!',
    price: `9.99/ea`,
    totalPrice: 19.98,
    subscriberPrice: '16.98',
    subscriberTotalPrice: 16.98,
    subscriberDiscount: '15%',
    sticker: true,
    stickerColor: '#FF8D6D',
    stickerText: 'Seasonal',
    subscriberStickerText: '15% OFF',
    stickerTextColor: 'night-800',
    disclaimer: '$19.98',
    subscriberDisclaimer: '$8.49/ea',
    thumbnail: '/img/rebrand/PSB-Square.webp',
    items: [
      {
        id: '231',
        quantity: 2,
        external_id: '231_01001601',
        offer: 'ea0e76dc9ac911ee941396a3b9f112ae',
      },
    ],
    images: ['/img/rebrand/PSB-Square.webp'],
  },
  'Plain Chicken Breasts': {
    sortOrder: 14,
    id: 'Plain Chicken Breasts',
    live: true,
    limit: 9999,
    name: 'Plain Chicken Breasts',
    delimiter: 1,
    volume: 1,
    description:
      "8 (4.5oz) PLAIN CHICKEN BREASTS: Add some better than organic chicken to your order! It's hormone-free, antibiotic-free, and never vaccinated. If you're looking for easy prep that still delivers on tenderness and flavor, then our plain chicken breasts are for you.",
    price: '18.99',
    oldPrice: '$22.99',
    totalPrice: 18.99,
    sticker: false,
    stickerColor: '',
    stickerText: '',
    stickerTextColor: '',
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/249/images/905/BonelessCK__59100.1700158119.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '249',
        quantity: 1,
        variant_id: 0,
        external_id: '249_CHK-BRST-PLAIN',
      },
    ],
  },
  'Wild-Caught Salmon': {
    sortOrder: 10,
    id: 'Wild-Caught Salmon',
    live: true,
    limit: 9999,
    name: 'Wild-Caught Salmon',
    delimiter: 1,
    volume: 1,
    description:
      "3 (6oz) KING SALMON OR SOCKEYE SALMON FILETS. You shouldn't have to swim upstream to find great salmon. Add three of our tasty, skin-on filets to your order to easily enjoy the best King or Sockeye Salmon America has to offer. It's all wild-caught, sustainably sourced, and packaged fresh on the boat to ensure a trustworthy and delicious meal every time.",
    price: '29.99',
    totalPrice: 29.99,
    oldPrice: '$35.99',
    sticker: false,
    thumbnail:
      'https://cdn11.bigcommerce.com/s-e10kokolz/products/245/images/884/salmon__74979.1698349028.386.513.jpg?c=1',
    disclaimer: '',
    items: [
      {
        id: '245',
        quantity: 1,
        variant_id: 0,
        external_id: '245_SF-SALMON',
      },
    ],
  },
  'Wild-Caught Shrimp': {
    sortOrder: 8,
    id: 'Wild-Caught Shrimp',
    live: true,
    limit: 9999,
    name: 'Wild-Caught Shrimp',
    delimiter: 1,
    volume: 1,
    description:
      '2 (1lb) GULF SHRIMP PACKS: Our shrimp is peeled and deveined for easy prep and enjoyment. Every piece is sourced from trusted, local fishermen and women in the US. We’re not lying when we say our seafood is shrimply the best. Store-bought shrimp can’t compare to the quality, freshness, and flavor that our wild-caught shrimp delivers in every bite.',
    price: '39.99',
    totalPrice: 39.99,
    oldPrice: '$44.99',
    sticker: false,
    thumbnail: '/img/rebrand/shrimp-addon.jpeg',
    disclaimer: '',
    items: [
      {
        id: '250',
        quantity: 1,
        variant_id: 0,
        external_id: '250_SF-SHRIMP',
      },
    ],
  },
  'Chicken Wings': {
    sortOrder: 1,
    id: 'Chicken Wings',
    live: true,
    limit: 9999,
    name: 'Chicken Wings',
    delimiter: 1,
    volume: 1,
    description:
      '2 (1lb) JUMBO CHICKEN WING packs: Stop winging it at the grocery store! Our jumbo chicken wings give you more bang for your buck with tons of quality meat. They’re hormone-free, antibiotic-free, and never vaccinated, ensuring every wing is a juicy, tender, and trustworthy bite. (Approx. 8-10 wings per pound)',
    price: '14.99',
    totalPrice: 14.99,
    oldPrice: '$18.99',
    sticker: false,
    thumbnail: '/img/rebrand/chicken-wings.jpeg',
    disclaimer: '',
    items: [
      {
        id: '256',
        quantity: 1,
        variant_id: 0,
        external_id: '256_CHK-WING',
      },
    ],
  },
  'Beef Bone In Shortloin Streak': {
    sortOrder: 14,
    id: 'Beef Bone In Shortloin Streak',
    live: false,
    limit: 9999,
    name: 'Beef Bone In Shortloin Streak',
    delimiter: 1,
    volume: 1,
    description: '',
    price: '56.00',
    totalPrice: 56.0,
    sticker: false,
    thumbnail: '/img/rebrand/beef-bone-in-shortloin-steak.jpeg',
    disclaimer: '',
    items: [
      {
        id: '263',
        quantity: 1,
        variant_id: 0,
        external_id: '263_5042751',
      },
    ],
  },
  'Beef Strip Cut Sirloin Steak': {
    sortOrder: 15,
    id: 'Beef Strip Cut Sirloin Steak',
    live: false,
    limit: 9999,
    name: 'Beef Strip Cut Sirloin Steak',
    delimiter: 1,
    volume: 1,
    price: '25.00',
    totalPrice: 25.0,
    items: [
      {
        id: '264',
        quantity: 1,
        variant_id: 0,
        external_id: '264_5042771',
      },
    ],
  },
  'Beef Top Sirloin Steak': {
    sortOrder: 16,
    id: 'Beef Top Sirloin Steak',
    live: false,
    limit: 9999,
    name: 'Beef Top Sirloin Steak',
    delimiter: 1,
    volume: 1,
    price: '$39.00',
    totalPrice: 39.0,
    items: [
      {
        id: '265',
        quantity: 1,
        variant_id: 0,
        external_id: '265_5042781',
      },
    ],
  },
  'Beef Flat Iron Steak': {
    sortOrder: 17,
    id: 'Beef Flat Iron Steak',
    live: false,
    limit: 9999,
    name: 'Beef Flat Iron Steak',
    delimiter: 1,
    volume: 1,
    price: '$36.00',
    totalPrice: 36.0,
    items: [
      {
        id: '266',
        quantity: 1,
        variant_id: 0,
        external_id: '266_5042691',
      },
    ],
  },
  'Sirloin Steak Tips': {
    sortOrder: 18,
    id: 'Sirloin Steak Tips',
    live: false,
    limit: 9999,
    name: 'Sirloin Steak Tips',
    delimiter: 1,
    volume: 1,
    price: '$33.00',
    totalPrice: 33.0,
    items: [
      {
        id: '267',
        quantity: 1,
        variant_id: 0,
        external_id: '267_BF-STK-TIPS',
      },
    ],
  },
  'Boneless Skinless Chicken Thighs': {
    sortOrder: 19,
    id: 'Boneless Skinless Chicken Thighs',
    live: false,
    limit: 9999,
    name: 'Boneless Skinless Chicken Thighs',
    delimiter: 1,
    volume: 1,
    price: '$16.00',
    totalPrice: 16.0,
    items: [
      {
        id: '268',
        quantity: 1,
        variant_id: 0,
        external_id: '268_5042601',
      },
    ],
  },
  'Garlic Pepper Boneless Skinless Chicken Breasts': {
    sortOrder: 20,
    id: 'Garlic Pepper Boneless Skinless Chicken Breasts',
    live: false,
    limit: 9999,
    name: 'Garlic Pepper Boneless Skinless Chicken Breasts',
    delimiter: 1,
    volume: 1,
    price: '$20.00',
    totalPrice: 20.0,
    items: [
      {
        id: '269',
        quantity: 1,
        variant_id: 0,
        external_id: '269_5042511',
      },
    ],
  },
  'Italian Seasoned Boneless Skinless Chicken Breasts': {
    sortOrder: 21,
    id: 'Italian Seasoned Boneless Skinless Chicken Breasts',
    live: false,
    limit: 9999,
    name: 'Italian Seasoned Boneless Skinless Chicken Breasts',
    delimiter: 1,
    volume: 1,
    price: '$20.00',
    totalPrice: 20.0,
    items: [
      {
        id: '270',
        quantity: 1,
        variant_id: 0,
        external_id: '270_5042501',
      },
    ],
  },
  'Chicken Leg Lollipop Drumette': {
    sortOrder: 22,
    id: 'Chicken Leg Lollipop Drumette',
    live: false,
    limit: 9999,
    name: 'Chicken Leg Lollipop Drumette',
    delimiter: 1,
    volume: 1,
    price: '$17.00',
    totalPrice: 17.0,
    items: [
      {
        id: '271',
        quantity: 1,
        variant_id: 0,
        external_id: '271_5042481',
      },
    ],
  },
  'Strip Cut Chicken': {
    sortOrder: 23,
    id: 'Strip Cut Chicken',
    live: false,
    limit: 9999,
    name: 'Strip Cut Chicken',
    delimiter: 1,
    volume: 1,
    price: '$16.00',
    totalPrice: 16.0,
    items: [
      {
        id: '272',
        quantity: 1,
        variant_id: 0,
        external_id: '272_CHK-BRST-STRIP',
      },
    ],
  },
  'Boneless Pork Chops': {
    sortOrder: 24,
    id: 'Boneless Pork Chops',
    live: false,
    limit: 9999,
    name: 'Boneless Pork Chops',
    delimiter: 1,
    volume: 1,
    price: '$24.00',
    totalPrice: 24.0,
    items: [
      {
        id: '273',
        quantity: 1,
        variant_id: 0,
        external_id: '273_5035031',
      },
    ],
  },
  'Ground Beef and Pork Belly Blend': {
    sortOrder: 25,
    id: 'Ground Beef and Pork Belly Blend',
    live: false,
    limit: 9999,
    name: 'Ground Beef and Pork Belly Blend',
    delimiter: 1,
    volume: 1,
    price: '$24.00',
    totalPrice: 24.0,
    items: [
      {
        id: '274',
        quantity: 1,
        variant_id: 0,
        external_id: '274_5053521',
      },
    ],
  },
  'COD PACIFIC PORTIONS 4-6 B&S IVP': {
    sortOrder: 26,
    id: 'COD PACIFIC PORTIONS 4-6 B&S IVP',
    live: false,
    limit: 9999,
    name: 'COD PACIFIC PORTIONS 4-6 B&S IVP',
    delimiter: 1,
    volume: 1,
    price: '$25.00',
    totalPrice: 25.0,
    items: [
      {
        id: '275',
        quantity: 1,
        variant_id: 0,
        external_id: '275_1000701',
      },
    ],
  },
  'HALIBUT PORTIONS 4-6Z B&S IVP': {
    sortOrder: 27,
    id: 'HALIBUT PORTIONS 4-6Z B&S IVP',
    live: false,
    limit: 9999,
    name: 'HALIBUT PORTIONS 4-6Z B&S IVP',
    delimiter: 1,
    volume: 1,
    price: '$30.00',
    totalPrice: 30.0,
    items: [
      {
        id: '276',
        quantity: 1,
        variant_id: 0,
        external_id: '276_1000700',
      },
    ],
  },
}
