import { CartType } from './carts'

export interface User {
  bcCustomerId: number
  createdAt: string
  deletedAt: string | null
  email: string
  id: number
  role: string
  updatedAt: string
  username: string
  firstName: string
  lastName: string
}

export interface Customer {
  accepts_product_review_abandoned_cart_emails: boolean
  authentication: { force_password_reset: boolean }
  channel_ids: number[] | null
  company: string
  customer_group_id: number
  date_created: string
  date_modified: string
  email: string
  first_name: string
  id: number
  last_name: string
  notes: string
  phone: string
  registration_ip_address: string
  tax_exempt_category: string
}

export enum UserActionType {
  'SET_USER',
  'SET_LOADING',
  'LOGOUT',
  'LOGIN',
  'SET_TOKEN',
  'SET_CART',
  'SET_SUBSCRIBER',
}

interface ActionStructure<ActionType, Payload> {
  type: ActionType
  payload: Payload
}

interface ActionStructureSimple<ActionType> {
  type: ActionType
}

export interface UserContextState {
  isLoading: boolean
  user: User | null
  customer: Customer | null
  token: string | null
  hydrated: boolean
  cart: CartType | null
  isSubscriber: boolean | false
}

export type UserAction =
  | ActionStructure<
      UserActionType.SET_USER,
      { user: User; customer: Customer; isSubscriber: boolean }
    >
  | ActionStructure<UserActionType.SET_LOADING, boolean>
  | ActionStructure<
      UserActionType.LOGIN,
      {
        token: string
        user: User
        customer: Customer
      }
    >
  | ActionStructureSimple<UserActionType.LOGOUT>
  | ActionStructure<UserActionType.SET_TOKEN, string | null>
  | ActionStructure<UserActionType.SET_CART, CartType | null>
  | ActionStructure<UserActionType.SET_SUBSCRIBER, boolean | false>

export interface UserContextType {
  dispatch: (action: UserAction) => void
  state: UserContextState
}
