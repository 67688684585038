/** Auth **/
export const CURRENT_USER = 'auth/current'
export const USER = 'auth/user'
export const USERS = 'auth/users'
export const LOGIN = 'auth/login'
export const RESET_PASSWORD_EMAIL = 'auth/reset-password-email'
export const MAGIC_LINK_EMAIL = 'auth/magic-link-email'
export const LOGOUT = 'auth/logout'
export const REGISTER = 'auth/register'
export const OG_AUTH_SMI = 'auth/ordergroove-smi'
export const BC_SSO_LOGIN = 'bigcommerce/auth/login'
export const PRODUCTS = 'bigcommerce/products'
export const CATEGORIES = 'bigcommerce/categories'
export const COUPONS = 'bigcommerce/coupon'
export const PAYMENT_METHODS = 'payment-methods'
export const ORDERS = 'bigcommerce/orders'
export const ADDRESSES = 'bigcommerce/addresses'
export const CUSTOMER = 'bigcommerce/customer'
export const CUSTOMER_INSTRUMENTS = 'bigcommerce/customer/instruments'
export const CARTS = 'bigcommerce/carts'
export const CHECKOUTS = 'bigcommerce/checkouts'
export const GIFTCARDS = 'bigcommerce/gift-cards'
export const BOTTOM_LINE_REVIEWS = 'reviews'
export const REVIEWS = 'reviews/all'
export const LOYALTY = 'loyalty'
export const NEWSLETTER_SIGNUP = 'newsletter/signup'
export const MARCH_MEATNESS = 'march-meatness'
export const MEATNESS_SIGNUP = 'meatness/signup'
export const SUB_SVC = 'sub-svc'
export const SUBSCRIPTIONS = 'sub-orders'
export const SUBSCRIPTION_FREQUENCY = 'change-frequency'
export const SUBSCRIPTION_QUANTITY = 'change-quantity'
export const SUBSCRIPTION_DATE = 'change-date'
export const SUBSCRIPTION_CANCEL = 'cancel'
export const SUBSCRIPTION_ORDER_CANCEL = 'cancel-order'
export const SUBSCRIPTION_ORDER_CHANGE_DATE = 'change-order-date'
export const SUBSCRIPTION_REACTIVATE = 'reactivate'
export const SUBSCRIPTION_PAYMENTS = 'payments'
export const SUBSCRIPTION_SHIPPING = 'change-shipping-address'
export const SUBSCRIPTION_LTV = 'ltv'
export const SUBSCRIPTION_PRODUCT = 'products'
export const SUBSCRIBER_STATUS = 'subscriber-status'
export const CREATE_NEXT_ORDER_ADD_ON = 'create-order-add-on'
export const REMOVE_NEXT_ORDER_ADD_ON = 'remove-next-order-add-on'
export const EDIT_NEXT_ORDER_ADD_ON = 'edit-next-order-add-on'
export const EDIT_ORDER_EXTRA_DATA = 'edit-order-extra-data'
export const OFFER_PROFILE = 'offer-profiles'
export const EDIT_OFFER_PROFILE = 'change-sub-offer-profile'
export const CREATE_ONE_TIME_DISCOUNT = 'create-incentive'
