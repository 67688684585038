import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import _, { get } from 'lodash'
import {
  ADDRESSES,
  CARTS,
  CATEGORIES,
  CHECKOUTS,
  CURRENT_USER,
  USER,
  USERS,
  CUSTOMER,
  CUSTOMER_INSTRUMENTS,
  BC_SSO_LOGIN,
  LOGIN,
  LOGOUT,
  ORDERS,
  PAYMENT_METHODS,
  PRODUCTS,
  REGISTER,
  RESET_PASSWORD_EMAIL,
  MAGIC_LINK_EMAIL,
  OG_AUTH_SMI,
  BOTTOM_LINE_REVIEWS,
  REVIEWS,
  LOYALTY,
  NEWSLETTER_SIGNUP,
  MARCH_MEATNESS,
  COUPONS,
  SUB_SVC,
  SUBSCRIPTION_FREQUENCY,
  SUBSCRIPTION_QUANTITY,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_REACTIVATE,
  SUBSCRIPTION_ORDER_CHANGE_DATE,
  SUBSCRIPTION_SHIPPING,
  SUBSCRIPTION_ORDER_CANCEL,
  SUBSCRIPTION_LTV,
  SUBSCRIPTION_PRODUCT,
  SUBSCRIBER_STATUS,
  CREATE_NEXT_ORDER_ADD_ON,
  REMOVE_NEXT_ORDER_ADD_ON,
  EDIT_NEXT_ORDER_ADD_ON,
  EDIT_ORDER_EXTRA_DATA,
  OFFER_PROFILE,
  EDIT_OFFER_PROFILE,
  CREATE_ONE_TIME_DISCOUNT,
} from '../constants/api'

import { LoginData, RegisterData } from '../types/auth'
import { CART_ID, COUPON_CODE, DEVICE_ID, TOKEN } from '../constants/cookies'
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'
import {
  BracketType,
  SubmissionType,
} from '@components/march-meatness/utils/DataTypes'
import { nextOrderAddOnType } from 'types/nextOrderAddOns'

const create = (
  baseURL = `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/`
) => {
  const api = axios.create({
    baseURL,
    headers: { Accept: 'application/vnd.api+json' },
    timeout: 120000,
  })

  api.interceptors.request.use((config) => {
    const token = get(config, 'params.token')

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  })

  api.interceptors.response.use(
    (res: AxiosResponse) => {
      return res
    },
    (err) => {
      console.log(err)
      if (err.response?.status === 401) {
        Cookies.remove(TOKEN)
      }
      return Promise.reject(err)
    }
  )

  const urlFromTemplate = (url: string, id: string | number) => {
    return url.replace('{id}', `${id}`)
  }

  const getHeaders = (token?: string, deviceId?: string) => {
    token = token || Cookies.get(TOKEN)
    deviceId = deviceId || Cookies.get(DEVICE_ID)

    if (!deviceId) {
      deviceId = uuid()
      Cookies.set(DEVICE_ID, deviceId, {
        expires: 365,
        sameSite: 'strict',
      })
    }

    return {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(deviceId ? { 'Unique-Device-Id': deviceId } : {}),
    }
  }

  const getRequest = (
    url: string,
    config?: AxiosRequestConfig & { signal?: AbortSignal }
  ) => (
    id?: string | number,
    token?: string,
    deviceId?: string,
    controller?: AbortController
  ) => {
    const headers = getHeaders(token, deviceId)
    return api.get(id ? urlFromTemplate(url, id) : url, {
      signal: controller?.signal,
      ...(config || {}),
      headers,
    } as AxiosRequestConfig & { signal?: AbortSignal })
  }

  const postRequest = (
    url: string,
    config?: AxiosRequestConfig & {
      signal?: AbortSignal
      withCredentials?: boolean
      credentials?: string
      origin?: string
    }
  ) => (
    body?: {},
    token?: string,
    deviceId?: string,
    controller?: AbortController
  ) => {
    const headers = getHeaders(token, deviceId)
    return api.post(url, body, {
      signal: controller?.signal,
      ...(config || {}),
      headers,
    } as AxiosRequestConfig & { signal?: AbortSignal; withCredentials?: boolean; credentials?: string; origin?: string })
  }

  const putRequest = (
    url: string,
    config?: AxiosRequestConfig & { signal?: AbortSignal }
  ) => (
    id?: string | number,
    body?: {},
    token?: string,
    deviceId?: string,
    controller?: AbortController
  ) => {
    const headers = getHeaders(token, deviceId)
    return api.put(id ? urlFromTemplate(url, id) : url, body, {
      signal: controller?.signal,
      ...(config || {}),
      headers,
    } as AxiosRequestConfig & { signal?: AbortSignal })
  }

  const deleteRequest = (
    url: string,
    config?: AxiosRequestConfig & { signal?: AbortSignal }
  ) => (
    id?: string | number,
    token?: string,
    deviceId?: string,
    controller?: AbortController
  ) => {
    const headers = getHeaders(token, deviceId)
    return api.delete(id ? urlFromTemplate(url, id) : url, {
      signal: controller?.signal,
      ...(config || {}),
      headers,
    })
  }

  /** Current User **/
  const getCurrentUser = getRequest(CURRENT_USER)
  const editCurrentUser = (userData: {
    firstName?: string
    lastName?: string
    email?: string
    password?: string
  }) => putRequest(CURRENT_USER)(undefined, userData)

  /** Users **/
  const getUser = (userId: string | number) =>
    getRequest(USER, { params: { userId } })()
  const getUsers = (page = 1, search?: string) =>
    getRequest(USERS, { params: { page, search } })()
  const editUser = (userData: {
    userId: string
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    bcCustomerId?: number
    role?: string
  }) => putRequest(USER)(undefined, userData)

  /** SSO Login  **/
  const loginWithSSO = (
    destinationUrl: string,
    customerId?: string | number,
    abortController?: AbortController
  ) => {
    const destination = encodeURIComponent(destinationUrl)
    return getRequest(
      `${BC_SSO_LOGIN}?customerId=${customerId}&destinationUrl=${destination}`
    )(undefined, undefined, undefined, abortController)
  }

  /** Create Payment Method Url **/
  const createPaymentMethodUrl = (
    customerId?: string | number,
    abortController?: AbortController
  ) => {
    return loginWithSSO(
      'https://store.goodranchers.com/account.php?action=payment_methods',
      customerId,
      abortController
    )
  }

  /** Payment Methods **/
  const getPaymentMethods = (userId?: string | number) =>
    getRequest(PAYMENT_METHODS, { params: { userId } })()
  const createPaymentMethod = postRequest(PAYMENT_METHODS)
  const deletePaymentMethod = (id: number | string, userId?: string | number) =>
    deleteRequest(`${PAYMENT_METHODS}/{id}`, { params: { userId } })(id)

  /** Auth **/
  const login = (loginData: LoginData) => postRequest(LOGIN)(loginData)
  const register = (registrationData: RegisterData) =>
    postRequest(REGISTER)(registrationData)
  const logout = (token?: string) => postRequest(LOGOUT)({}, token)
  const sendResetPasswordEmail = (email: string) =>
    postRequest(RESET_PASSWORD_EMAIL)({ email })
  const sendOGCookie = async (
    bcCustomerId?: number,
    abortController?: AbortController
  ) =>
    getRequest(OG_AUTH_SMI, { params: { bcCustomerId } })(
      undefined,
      undefined,
      undefined,
      abortController
    )

  const sendMagicLinkEmail = (emailInfo: {
    recipients: string[]
    subject: string
    body: string
    redirect: string
  }) => postRequest(MAGIC_LINK_EMAIL)(emailInfo)

  /** Addresses **/
  const getAddresses = (userId?: string | number) =>
    getRequest(ADDRESSES, { params: { userId } })()
  const createAddress = (
    address: { [key: string]: unknown },
    userId?: string | number
  ) => postRequest(ADDRESSES)({ address, userId })
  const editAddress = (
    address: { [key: string]: unknown },
    userId?: string | number
  ) => putRequest(ADDRESSES)(undefined, { address, userId })
  const deleteAddress = (
    addressId: string | number,
    userId?: string | number
  ) =>
    deleteRequest(
      [
        `${ADDRESSES}?`,
        userId ? `userId=${userId}&` : '',
        `addressId=${addressId}`,
      ].join('')
    )()

  /** Customer **/
  const getCustomer = (email: string, controller?: AbortController) =>
    getRequest(`${CUSTOMER}?email=${email}`)(
      undefined,
      undefined,
      undefined,
      controller
    )
  const editCustomer = (body: any) => putRequest(CUSTOMER)(undefined, body)

  /** Products **/
  const getProducts = getRequest(PRODUCTS)

  const getProductByID = async (productID: number) => {
    if (!productID) {
      console.error('getProductByID - no product ID given')
    }
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/bigcommerce/product/${productID}`
      )
      if (!response.ok) {
        console.error(
          'getProductByID - did not receive Response Okay status: ' +
            response.statusText
        )
      }
      return response.json()
    } catch (error) {
      console.error('error getting bottomline review by product ID', error)
    }
  }

  /** Categories **/
  const getCategories = getRequest(CATEGORIES)

  const getCustomerInstruments = getRequest(`${CUSTOMER_INSTRUMENTS}`)

  /** Orders **/
  const getOrders = (
    page: number = 1,
    userId?: string | number,
    controller?: AbortController
  ) =>
    getRequest(ORDERS, { params: { page, userId } })(
      undefined,
      undefined,
      undefined,
      controller
    )
  const getOrder = getRequest(`${ORDERS}/{id}`)
  const editOrder = putRequest(`${ORDERS}/{id}`)
  const getOrderProducts = getRequest(`${ORDERS}/{id}/products`)
  const getOrderShippingAddresses = getRequest(
    `${ORDERS}/{id}/shipping-addresses`
  )

  /** Carts **/
  const getCart = async (abortController?: AbortController) => {
    try {
      const cartId = Cookies.get(CART_ID)
      if (!cartId) return null
      const res = await getRequest(`${CARTS}/{id}`)(
        cartId,
        undefined,
        undefined,
        abortController
      )
      const { data } = res.data
      if (!data) {
        Cookies.remove(CART_ID)
        return null
      }
      return res
    } catch (e) {
      console.log(e)
      if (!e.toString().match(/request aborted/i)) {
        Cookies.remove(CART_ID)
      }
      return null
    }
  }

  const addItemToCart = async (payload: any) => {
    const cartId = Cookies.get(CART_ID)
    const addItem = !cartId
      ? postRequest(CARTS)
      : postRequest(`${CARTS}/${cartId}/items`)
    const { NEXT_PUBLIC_BC_CHANNEL_ID } = process.env
    const res = await addItem({
      channelId: Number(NEXT_PUBLIC_BC_CHANNEL_ID),
      lineItems: [payload],
    })
    const { data } = res.data
    if (data?.id) {
      Cookies.set(CART_ID, data.id, {
        expires: 365,
        sameSite: 'strict',
      })
    }
    // check if there is a stored coupon and add it to the cart
    await addStoredCouponToCart()
    return res
  }

  const addItemsToCart = async (payload: any) => {
    const cartId = Cookies.get(CART_ID)
    const addItem = !cartId
      ? postRequest(CARTS)
      : postRequest(`${CARTS}/${cartId}/items`)
    const { NEXT_PUBLIC_BC_CHANNEL_ID } = process.env
    const res = await addItem({
      channelId: Number(NEXT_PUBLIC_BC_CHANNEL_ID),
      lineItems: payload,
    })
    const { data } = res.data
    if (data?.id) {
      Cookies.set(CART_ID, data.id, {
        expires: 365,
        sameSite: 'strict',
      })
    }
    // check if there is a stored coupon and add it to the cart
    await addStoredCouponToCart()
    return res
  }

  const addGiftCardToCart = async (payload: any) => {
    const cartId = Cookies.get(CART_ID)
    const addItem = !cartId
      ? postRequest(CARTS)
      : postRequest(`${CARTS}/${cartId}/items`)
    const { NEXT_PUBLIC_BC_CHANNEL_ID } = process.env
    const res = await addItem({
      channelId: Number(NEXT_PUBLIC_BC_CHANNEL_ID),
      giftCertificates: [payload],
    })
    const { data } = res.data
    if (data?.id) {
      Cookies.set(CART_ID, data.id, {
        expires: 365,
        sameSite: 'strict',
      })
    }
    // check if there is a stored coupon and add it to the cart
    await addStoredCouponToCart()
    return res
  }

  const editCartItem = (itemId: string, payload: any) => {
    const cartId = Cookies.get(CART_ID)
    if (!cartId) return null

    return putRequest(`${CARTS}/${cartId}/items/${itemId}`)(undefined, payload)
  }

  const editCartItemSubscriptionOptions = (
    itemId: string,
    newItemOptions: any
  ) => {
    const cartId = Cookies.get(CART_ID)
    if (!cartId) return null

    const { NEXT_PUBLIC_BC_CHANNEL_ID } = process.env

    const body = {
      newItemOptions,
      channelId: Number(NEXT_PUBLIC_BC_CHANNEL_ID),
    }

    return putRequest(
      `${CARTS}/${cartId}/items/${itemId}/subscription-options`
    )(undefined, body)
  }

  const deleteCartItem = async (itemId: string) => {
    const cartId = Cookies.get(CART_ID)
    if (!cartId) return null
    const res = await deleteRequest(`${CARTS}/${cartId}/items/${itemId}`)()
    const cartDeleted = !res?.data?.data
    if (cartDeleted) {
      Cookies.remove(CART_ID)
    }
    return res
  }

  const createCartRedirectUrl = (
    cartId?: string,
    token?: string,
    deviceId?: string,
    abortController?: AbortController
  ) => {
    cartId = cartId || Cookies.get(CART_ID)
    if (!cartId) return null
    return postRequest(`${CARTS}/${cartId}/redirect-url`)(
      undefined,
      token,
      deviceId,
      abortController
    )
  }

  const addStoredCouponToCart = async (coupon?: string) => {
    const cartId = Cookies.get(CART_ID)
    const couponCode = Cookies.get(COUPON_CODE)
    try {
      const response = await fetch(`/api/coupons/addCouponToCart`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cartId: cartId,
          coupon: coupon || couponCode,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      return data
    } catch (error) {
      console.error('Failed to add coupon to cart: sorry', error)
    }
  }

  const deleteCoupon = async (coupon: string) => {
    try {
      if (![coupon].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const cartId = Cookies.get(CART_ID)

      const res = await deleteRequest(
        `${CHECKOUTS}/${cartId}/coupons/${coupon}`
      )()
      const { data } = res
      return data
    } catch (error) {
      console.error('error removing coupon', error)
      return null
    }
  }

  const getReviewsBottomLine = async (productId: string) => {
    if (_.isEmpty(productId)) {
      console.error('getReviewsBottomLine - no product ID given')
    }
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${BOTTOM_LINE_REVIEWS}/${productId}`
      )
      if (!response.ok) {
        console.error(
          'getReviewBottomLineById - did not receive Response Okay status: ' +
            response.statusText
        )
      }
      return response.json()
    } catch (error) {
      console.error('error getting bottomline review by product ID', error)
    }
  }

  const getReviews = async (
    productId: string,
    page?: string,
    perPage?: string
  ) => {
    if (_.isEmpty(productId)) {
      console.error('getReviewsBottomLine - no product ID given')
    }
    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${REVIEWS}/${productId}?` +
          new URLSearchParams({
            page: page || '1',
            perPage: perPage || '5',
          })
      )
      if (!response.ok) {
        console.error(
          'getReviewById - did not receive Response Okay status: ' +
            response.statusText
        )
      }
      return response.json()
    } catch (error) {
      console.error('error getting all reviews by product ID', error)
    }
  }

  const createLoyaltyOrder = async (
    orderId: string,
    ipAddress: string,
    userAgent: string,
    token?: string,
    deviceId?: string,
    abortController?: AbortController
  ) => {
    return postRequest(`${LOYALTY}/orders/${orderId}`)(
      { ipAddress, userAgent },
      token,
      deviceId,
      abortController
    )
  }

  const getPoints = async (
    customer_id: number,
    abortController?: AbortController
  ) => {
    try {
      const customerId = customer_id
      const res = await getRequest(`${LOYALTY}/${customerId}`)(
        customerId,
        undefined,
        undefined,
        abortController
      )
      const { data } = res.data
      if (!data) {
        return null
      }
      return res
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const getReferrals = async (
    customer_id: number,
    abortController?: AbortController
  ) => {
    try {
      const customerId = customer_id
      const res = await getRequest(`${LOYALTY}/${customerId}/referrals`)(
        customerId,
        undefined,
        undefined,
        abortController
      )
      const { data } = res.data
      if (!data) {
        return null
      }
      return res
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const getRedemptions = async (
    customer_id: number,
    abortController?: AbortController
  ) => {
    try {
      const customerId = customer_id
      const res = await getRequest(`${LOYALTY}/${customerId}/redemptions`)(
        customerId,
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      if (!data) {
        return null
      }
      return data
    } catch (e) {
      console.log(e)
      return null
    }
  }

  const createRedemptions = (
    customer_id: number,
    option_id: number,
    abortController?: AbortController
  ) => {
    const customerId = customer_id
    const redemptionOptionId = option_id
    if (!customerId) return null
    return postRequest(`${LOYALTY}/${customerId}/redemptions`)(
      { customerId, redemptionOptionId },
      undefined,
      undefined,
      abortController
    )
  }

  const signupNewsletter = async (
    email: string,
    firstName?: string,
    lastName?: string,
    abortController?: AbortController
  ) => {
    if (_.isEmpty(email)) {
      console.error('signupNewsLetter - no email given')
    }

    try {
      return postRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${NEWSLETTER_SIGNUP}`
      )({ email, firstName, lastName }, undefined, undefined, abortController)
    } catch (error) {
      console.error('error signing user up for newsletter', error)
    }
  }

  const getUserBracket = async (
    customer_email: string,
    abortController?: AbortController
  ) => {
    const customerEmail = customer_email
    try {
      const res = await postRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}`
      )({ customerEmail }, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting user bracket', error)
      return null
    }
  }

  const postUserBracket = async (
    customer_email: string,
    bracket: BracketType,
    name: string,
    abortController?: AbortController
  ) => {
    const customerEmail = customer_email
    try {
      const res = await postRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/submit`
      )({ customerEmail, bracket, name }, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error saving user bracket', error)
    }
  }

  const createGroup = async (
    bracket: BracketType,
    customer_email: string,
    group_name: string,
    name: string,
    abortController?: AbortController
  ) => {
    const customerEmail = customer_email
    const groupName = group_name
    const firstName = name
    try {
      const res = await postRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/group`
      )(
        { bracket, customerEmail, groupName, firstName },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error creating group bracket', error)
    }
  }

  const getGroups = async (
    customer_email: string,
    abortController?: AbortController
  ) => {
    const customerEmail = customer_email
    try {
      const res = await getRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/groups`
      )(customerEmail, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting group bracket', error)
      return null
    }
  }

  const getGroup = async (
    groupId: string,
    abortController?: AbortController
  ) => {
    try {
      const res = await getRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/group/${groupId}`
      )(groupId, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting group bracket', error)
      return null
    }
  }

  const updateGroup = async (
    customer_email: string,
    bracket: BracketType,
    group_id: string,
    join: boolean,
    name: string,
    abortController?: AbortController
  ) => {
    try {
      const res = await putRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/group`
      )(
        undefined,
        { customerEmail: customer_email, bracket, groupId: group_id, join, firstName: name },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error updating group bracket', error)
    }
  }

  const getLiveBracket = async (abortController?: AbortController) => {
    try {
      const res = await getRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/final`
      )(undefined, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting final bracket', error)
      return null
    }
  }

  const postLiveBracket = async (
    bracket: BracketType,
    abortController?: AbortController
  ) => {
    try {
      const res = await postRequest(
        `${process.env.NEXT_PUBLIC_BASE_API_URL}/api/v1/${MARCH_MEATNESS}/final`
      )({ bracket }, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error updating final bracket', error)
    }
  }

  const signupMeatness = async (
    email: string,
    firstName: string,
    lastName: string,
    klaviyo: any
  ) => {
    if (_.isEmpty(email)) {
      console.error('signupMeatness - no email given')
    }
    let res = null

    try {
      res = await axios.post(`${klaviyo.klaviyo.url}/subscribe`, {
        body: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          list_id: klaviyo.klaviyo.listID,
        },
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: klaviyo.klaviyo.token,
        },
      })
      return res
    } catch (error) {
      console.log(error)
      return res
    }
  }

  const getCoupon = async (
    couponId?: string,
    abortController?: AbortController
  ) => {
    try {
      const res = await getRequest(`${COUPONS}/${couponId}`)(
        couponId,
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting coupon code', error)
      return null
    }
  }

  const getSubscriptions = async (
    customerId: string | number,
    abortController?: AbortController
  ) => {
    try {
      if (![customerId].every(Boolean)) {
        throw Error('no customerId given')
      }
      const res = await getRequest(`${SUB_SVC}?customerId=${customerId}`)(
        undefined,
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting subscriptions', error)
      return null
    }
  }

  const changeSubscriptionFrequency = async (
    subscriptionId: string,
    frequency: string,
    abortController?: AbortController
  ) => {
    try {
      if (![subscriptionId, frequency].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_FREQUENCY}`)(
        { subscriptionId, frequency },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error changing subscription frequency', error)
      return null
    }
  }

  const changeSubscriptionQuantity = async (
    subscriptionId: string,
    quantity: number,
    abortController?: AbortController
  ) => {
    try {
      if (![subscriptionId, quantity].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_QUANTITY}`)(
        { subscriptionId, quantity },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error changing subscription quantity', error)
      return null
    }
  }

  const cancelSubscription = async (
    subscriptionId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![subscriptionId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_CANCEL}`)(
        { subscriptionId },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error cancelling subscription', error)
      return null
    }
  }

  const reactiveSubscription = async (
    subscriptionId: string,
    every: string,
    everyPeriod: string,
    startDate: string,
    abortController?: AbortController
  ) => {
    try {
      if (![subscriptionId, every, everyPeriod, startDate].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_REACTIVATE}`)(
        { subscriptionId, every, everyPeriod, startDate },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error reactivating subscription', error)
      return null
    }
  }

  const changeSubscriptionOrderDate = async (
    nextOrder: any,
    placeDate: string,
    abortController?: AbortController
  ) => {
    try {
      if (![nextOrder, placeDate].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(
        `${SUB_SVC}/${SUBSCRIPTION_ORDER_CHANGE_DATE}`
      )({ nextOrder, placeDate }, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error changing subscription order date', error)
      return null
    }
  }

  const changeSubscriptionShippingAddress = async (
    body: any,
    abortController?: AbortController
  ) => {
    try {
      if (
        ![
          body.subscriptionId,
          body.customer,
          body.addressType,
          body.firstName,
          body.lastName,
          body.address,
          body.city,
          body.state,
          body.zipPostalCode,
          body.countryCode,
        ].every(Boolean)
      ) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_SHIPPING}`)(
        body,
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error changing subscription shipping address', error)
      return null
    }
  }

  const cancelSubscriptionOrder = async (
    orderId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![orderId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${SUBSCRIPTION_ORDER_CANCEL}`)(
        { orderId },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error cancelling subscription order', error)
      return null
    }
  }

  const getSubscriptionLTV = async (
    customerId: string,
    subscriptionId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![subscriptionId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await getRequest(
        `${SUB_SVC}/${SUBSCRIPTION_LTV}?customerId=${customerId}&subscriptionId=${subscriptionId}`
      )(undefined, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting subscription LTV', error)
      return null
    }
  }

  const getSubscriptionProduct = async (
    productId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![productId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await getRequest(
        `${SUB_SVC}/${SUBSCRIPTION_PRODUCT}?productId=${productId}`
      )(undefined, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting subscription product', error)
      return null
    }
  }

  const getSubscriberStatus = async (
    customerId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![customerId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await getRequest(
        `${SUB_SVC}/${SUBSCRIBER_STATUS}/?customerId=${customerId}`
      )(undefined, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting subscriber status', error)
      return null
    }
  }

  const createNextOrderItem = async (
    addOnData: nextOrderAddOnType,
    abortController?: AbortController
  ) => {
    try {
      if (![addOnData].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await postRequest(`${SUB_SVC}/${CREATE_NEXT_ORDER_ADD_ON}/`)(
        { addOnData },
        undefined,
        undefined,
        abortController
      )

      return res
    } catch (error) {
      console.error('error adding add on', error)
      return null
    }
  }

  const deleteNextOrderItem = async (
    itemId: string,
    abortController?: AbortController
  ) => {
    try {
      if (![itemId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await postRequest(`${SUB_SVC}/${REMOVE_NEXT_ORDER_ADD_ON}`)(
        { itemId },
        undefined,
        undefined,
        abortController
      )
      return res
    } catch (error) {
      console.error('error adding add on', error)
      return null
    }
  }

  const editNextOrderItem = async (
    itemId: string,
    quantity: number,
    abortController?: AbortController
  ) => {
    try {
      if (![itemId].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }
      const res = await postRequest(`${SUB_SVC}/${EDIT_NEXT_ORDER_ADD_ON}`)(
        { item_id: itemId, quantity: quantity },
        undefined,
        undefined,
        abortController
      )

      return res
    } catch (error) {
      console.error('error adding add on', error)
      return null
    }
  }

  const editOrderExtraData = async (
    orderId: string,
    extraData: any,
    abortController?: AbortController
  ) => {
    try {
      if (![orderId, !!extraData].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(`${SUB_SVC}/${EDIT_ORDER_EXTRA_DATA}`)(
        { order_id: orderId, extra_data: extraData },
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error editing order extra data', error)
      return null
    }
  }

  const getOfferProfiles = async (abortController?: AbortController) => {
    try {
      const res = await getRequest(`${SUB_SVC}/${OFFER_PROFILE}`)(
        undefined,
        undefined,
        undefined,
        abortController
      )
      const { data } = res
      return data
    } catch (error) {
      console.error('error getting offer profiles', error)
      return null
    }
  }

  const editOfferProfile = async (
    subscriptionId: string,
    offer: string,
    abortController?: AbortController
  ) => {
    try {
      if (![!!subscriptionId, !!offer].every(Boolean)) {
        throw Error('missing body parameter(s)')
      }

      const res = await postRequest(
        `${SUB_SVC}/${EDIT_OFFER_PROFILE}/${subscriptionId}`
      )({ offer }, undefined, undefined, abortController)
      const { data } = res
      return data
    } catch (error) {
      console.error('error updating offer profile', error)
      return null
    }
  }

  const createOneTimeDiscount = async (
    incentiveName: string,
    discountType: string,
    discountTarget: string,
    discountField: string,
    discountAmount: string,
    stackingType: string,
    description: string,
    customerId: string,
    orderId: string,
    itemId: string,
    abortController?: AbortController
  ) => {
    try {
      if (
        ![
          incentiveName,
          customerId,
          orderId,
          itemId,
          description,
          stackingType,
          discountTarget,
          discountField,
          discountType,
          discountAmount,
        ].every(Boolean)
      ) {
        throw Error('missing body parameter(s)')
      }
      const res = await postRequest(`${SUB_SVC}/${CREATE_ONE_TIME_DISCOUNT}/`)(
        {
          customerId,
          orderId,
          itemId,
          incentiveName,
          description,
          discountType,
          discountAmount,
          discountField,
          discountTarget,
          stackingType,
        },
        undefined,
        undefined,
        abortController
      )
      return res || null
    } catch (error) {
      console.error('error creating discount', error)
      return null
    }
  }

  return {
    addItemsToCart,
    getCurrentUser,
    editCurrentUser,
    getUser,
    getUsers,
    editUser,
    login,
    register,
    logout,
    createPaymentMethodUrl,
    sendResetPasswordEmail,
    sendOGCookie,
    sendMagicLinkEmail,
    getPaymentMethods,
    createPaymentMethod,
    deletePaymentMethod,
    getAddresses,
    createAddress,
    editAddress,
    deleteAddress,
    getCustomer,
    editCustomer,
    getProducts,
    getCategories,
    getOrders,
    getOrder,
    editOrder,
    getOrderProducts,
    getOrderShippingAddresses,
    getCart,
    addItemToCart,
    addGiftCardToCart,
    editCartItem,
    editCartItemSubscriptionOptions,
    deleteCartItem,
    createCartRedirectUrl,
    addStoredCouponToCart,
    deleteCoupon,
    getReviewsBottomLine,
    getReviews,
    createLoyaltyOrder,
    getPoints,
    getReferrals,
    getRedemptions,
    createRedemptions,
    signupNewsletter,
    getUserBracket,
    postUserBracket,
    getLiveBracket,
    postLiveBracket,
    signupMeatness,
    getProductByID,
    getCoupon,
    getCustomerInstruments,
    getSubscriptions,
    changeSubscriptionFrequency,
    changeSubscriptionQuantity,
    cancelSubscription,
    reactiveSubscription,
    changeSubscriptionOrderDate,
    changeSubscriptionShippingAddress,
    cancelSubscriptionOrder,
    getSubscriptionLTV,
    getSubscriptionProduct,
    getSubscriberStatus,
    createNextOrderItem,
    deleteNextOrderItem,
    editNextOrderItem,
    editOrderExtraData,
    getOfferProfiles,
    editOfferProfile,
    createOneTimeDiscount,
    createGroup,
    getGroups,
    updateGroup,
    getGroup,
  }
}
const api = create()
export default api
