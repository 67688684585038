import { DEVICE_ID, TOKEN } from 'constants/cookies'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { createContext, useEffect, useReducer } from 'react'
import { v4 as uuid } from 'uuid'
import api from '../services/api'
import {
  UserAction,
  UserActionType,
  UserContextState,
  UserContextType,
} from '../types/user-context'

const initialState: UserContextState = {
  user: null,
  customer: null,
  hydrated: false,
  isLoading: true,
  token: null,
  cart: null,
  isSubscriber: false,
}

export const UserContext = createContext<UserContextType>({
  dispatch: () => null,
  state: initialState,
})

const storeReducer = (
  state: UserContextState,
  action: UserAction
): UserContextState => {
  switch (action.type) {
    case UserActionType.SET_USER:
      return {
        ...state,
        user: action.payload.user,
        customer: action.payload.customer,
        isSubscriber: action.payload.isSubscriber,
      }
    case UserActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case UserActionType.LOGOUT:
      api.logout().catch((error) => console.log(error))
      Cookies.remove(TOKEN)
      return { ...initialState, hydrated: true, isLoading: true }
    case UserActionType.LOGIN:
      return {
        ...state,
        hydrated: true,
        user: action.payload.user,
        customer: action.payload.customer,
        token: action.payload.token,
      }
    case UserActionType.SET_TOKEN:
      return { ...state, token: action.payload, hydrated: true }
    case UserActionType.SET_CART:
      return { ...state, cart: action.payload }
    case UserActionType.SET_SUBSCRIBER:
      return { ...state, isSubscriber: action.payload }
    default:
      throw new Error('Action type not found.')
  }
}

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storeReducer, initialState)
  const router = useRouter()

  useEffect(() => {
    let deviceId = Cookies.get(DEVICE_ID)

    if (!deviceId) {
      Cookies.set(DEVICE_ID, uuid(), {
        expires: 365,
        sameSite: 'strict',
      })
    }
  }, [])

  useEffect(() => {
    const tokenFromCookie = Cookies.get(TOKEN)
    const tokenFromQuery = router.query?.[TOKEN] as string | undefined
    const token = tokenFromQuery || tokenFromCookie || null
    dispatch({ payload: token, type: UserActionType.SET_TOKEN })
  }, [router.query])

  useEffect(() => {
    const controller =
      typeof AbortController !== 'undefined' ? new AbortController() : undefined
    if (state.hydrated) {
      if (!!state.token) {
        Cookies.set(TOKEN, state.token, {
          expires: 365,
          sameSite: 'strict',
        })

        api
          .getCurrentUser(undefined, undefined, undefined, controller)
          .then((res) => {
            const { user, customer, subscriber_status: isSubscriber } = res.data

            dispatch({
              payload: { user, customer, isSubscriber },
              type: UserActionType.SET_USER,
            })
          })
          .catch((err) => {
            if (!err.toString().match(/request aborted/i)) {
              console.error('GET USER ERROR:', err.message)
              try {
                Cookies.remove(TOKEN)
                dispatch({ type: UserActionType.LOGOUT })
              } catch (err) {
                console.error('CLEAN UP ERROR: ', err)
              }
            }
          })
          .finally(() => {
            dispatch({ payload: false, type: UserActionType.SET_LOADING })
          })
      } else {
        dispatch({ payload: false, type: UserActionType.SET_LOADING })
      }
    }

    return () => {
      try {
        if (controller) controller.abort()
      } catch (err) {
        console.error('Failed to cancel user fetch: ', err)
      }
    }
  }, [state.hydrated, state.token])

  useEffect(() => {
    const controller =
      typeof AbortController !== 'undefined' ? new AbortController() : undefined
    if (state.hydrated && !state.isLoading) {
      const getCart = async () => {
        try {
          const cartRes = await api.getCart(controller)
          const { data = null } = cartRes?.data || {}
          dispatch({ type: UserActionType.SET_CART, payload: data })
        } catch (e) {
          if (!e.toString().match(/request aborted/i)) {
            console.log(e)
            dispatch({ type: UserActionType.SET_CART, payload: null })
          }
        }
      }
      getCart()
    }
    return () => {
      try {
        if (controller) controller.abort()
      } catch (err) {
        console.error('Failed to cancel cart fetch: ', err)
      }
    }
  }, [state.hydrated, state.isLoading])

  return (
    <UserContext.Provider value={{ dispatch, state }}>
      {children}
    </UserContext.Provider>
  )
}
