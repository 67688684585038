import React, { createContext, useReducer, useEffect } from 'react'
import reducer, { initialState } from 'services'

interface GlobalContextType {
  dispatch: React.Dispatch<boolean>
  state: {
    drawerOpen: boolean
    lightContent: boolean
    overlayActive: boolean
    overlayLocked: boolean
    Modal: {
      modal: React.FunctionComponent
      locked?: boolean
      onClose?: (...rest: any[]) => unknown
    } | null
    cartCacheBuster: number
  }
}

const GlobalContext = createContext<any>({} as GlobalContextType)

const GlobalProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
